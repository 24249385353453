import { Text, View } from '@react-pdf/renderer'
import { numberFormat } from 'utilities'
import { AddOnsFooterStyles as styles } from './styles'
import { IQuoteLabourLine, IQuoteLine } from 'models'

interface FooterProps {
	items: IQuoteLabourLine[]
}

export const FooterQuoteLabourLines = ({ items }: FooterProps) => {
	// Calculate totals
	const labour_hours = items
		.map((item) => Number(item.labour_hours))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	const weekFee = items
		.map((item) => Number(item.labour_cost))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	return (
		<>
			<View style={styles.row}>
				<Text style={styles.description}>Sub Total (ex. GST)</Text>
				<Text style={styles.total}>{numberFormat.format(weekFee)}</Text>
			</View>
		</>
	)
}
