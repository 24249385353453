import React from 'react'
import { DateSelect, CreateFile } from 'common'

type WhiteCardProps = {
	values: {
		white_card_file: string
		white_card_date: string
		white_card_expiry_date: string
	}

	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
}

export const WhiteCard: React.FC<WhiteCardProps> = ({
	values,
	setFieldValue,
}) => {
	return (
		<>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">White Card</h3>
			<div>
				<div className="flex items-center px-2">
					<DateSelect
						title="Date"
						id="white_card_date"
						value={values.white_card_date}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expiry Date"
						id="white_card_expiry_date"
						value={values.white_card_expiry_date}
						onChange={setFieldValue}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="white_card_file"
						setFieldValue={setFieldValue}
						value={values.white_card_file}
					/>
				</div>
			</div>
		</>
	)
}
