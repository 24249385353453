import React from 'react'
import { DateSelect, CreateFile } from 'common'

type HighRiskWorksProps = {
	values: {
		high_risk_works_file: string
		high_risk_works_date: string
		high_risk_works_expiry_date: string
	}

	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
}

export const HighRiskWorks: React.FC<HighRiskWorksProps> = ({
	values,
	setFieldValue,
}) => {
	return (
		<>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">
				High Risk Works
			</h3>
			<div>
				<div className="flex items-center px-2">
					<DateSelect
						title="Date"
						id="high_risk_works_date"
						value={values.high_risk_works_date}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expiry Date"
						id="high_risk_works_expiry_date"
						value={values.high_risk_works_expiry_date}
						onChange={setFieldValue}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="high_risk_works_file"
						setFieldValue={setFieldValue}
						value={values.high_risk_works_file}
					/>
				</div>
			</div>
		</>
	)
}
