import { IQuoteAdditionalLine, IQuoteHireLine, IQuoteLabourLine, IQuoteLine } from 'models'

export const totalsFn = {
	calculatedLinePriceToTotal: (labourLines: IQuoteLabourLine[]): number => {
		const labourLinesTotal = labourLines.reduce((acc, curr) => acc + Number(curr.labour_cost), 0)
		return labourLinesTotal
	},
	calculateAdditionalTotal: (data: IQuoteAdditionalLine[]): number => {
		return data.reduce((acc, curr) => acc + Number(curr.total_cost), 0)
	},
	calculateWeekTotal: (lines: IQuoteHireLine[]): number => {
		const linesTotal = lines.reduce(
			(acc, curr) => acc + Number(curr.weekly_hire_fee),
			0
		)
		return linesTotal
	},
	calculateTotals: (
		edTotal: number,
		addOnsTotal: number,
		totalWeeklyHireCost: number
	): number => {
		return edTotal + addOnsTotal + totalWeeklyHireCost
	},
}
