import { IQuoteAdditionalLine, IRates } from 'models'

const initLine: IQuoteAdditionalLine = {
	id: null,
	type: '',
	description: '',
	duration_quantity: 0,
	fixed_charge: 0,
	total_cost: 0,
	addon_condition: '',
}

const add = (
	lines: IQuoteAdditionalLine[]
): IQuoteAdditionalLine[] => {
	lines.push(initLine)
	return lines
}

const remove = (
	index: number,
	quote_lines: IQuoteAdditionalLine[]
): IQuoteAdditionalLine[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const update = (
	index: number,
	quote_lines: IQuoteAdditionalLine[],
	field: string,
	value: string | number
): IQuoteAdditionalLine[] => {
	const newQuoteLines = quote_lines.map((line, i) => {
		if (i === index) {
			return {
				...line,
				[field]: value,
			}
		}
		return line
	})
	return newQuoteLines
}

const calculate = (
	lines: IQuoteAdditionalLine[],
	rates: IRates[]
): IQuoteAdditionalLine[] => {
	const newLines = lines.map((line) => {
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) return line

		const newLine: IQuoteAdditionalLine = {
			...line,
			fixed_charge: rate,
			total_cost: rate * Number(line.duration_quantity),
		}
		return newLine
	})
	return newLines
}

export const quoteAdditionalLineFunctions = {
	add,
	remove,
	update,
	calculate,
}