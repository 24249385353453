import clsx from 'clsx'
import {
	IQuoteAdditionalLine,
	IRates,
	IQuoteAdditionalLineFunctions,
	IQuoteForm,
} from 'models'
import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import '../customStyle.css'
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { FormikProps } from 'formik'
import { ConfirmationDialog, TextArea } from 'common'

interface IAdditionalLineProps {
	rates: IRates[]
	line: IQuoteAdditionalLine
	additionalLineHandlers: IQuoteAdditionalLineFunctions
	index: number
	formik: FormikProps<IQuoteForm>
}

export const QuoteAdditionalLine = ({
	rates,
	line,
	additionalLineHandlers,
	index,
	formik,
}: IAdditionalLineProps) => {
	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteAdditionalLine,
		value: string | number
	) => {
		additionalLineHandlers.update(index, field, value)
	}

	return (
		<tr>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">
				<Dropdown
					//hasLabel={false}
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type${index}`}
					value={line.type}
					onChange={(e) => {
						handleProductLineChange(index, 'type', e.target.value)
					}}
					className={`w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${
						(formik?.errors?.quote_additional_lines &&
						(formik.errors.quote_additional_lines as any)[index]?.type)
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
				<input
					id={`description${index}`}
					type="text"
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						(formik?.errors?.quote_additional_lines &&
						(formik.errors.quote_additional_lines as any)[index]?.description)
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					name="description"
					value={line.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
				<input
					id={`duration_quantity${index}`}
					type="number"
					min={0}
					className={`h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center ${
						(formik?.errors?.quote_additional_lines &&
						(formik.errors.quote_additional_lines as any)[index]?.duration_quantity)
							? 'ring-red-500 ring-1 animate-pulse'
							: ''
					}`}
					name="duration_quantity"
					value={line.duration_quantity}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'duration_quantity',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
				<input
					id={`fixed_charge${index}`}
					type="text"
					className={clsx(
						line.type
							? 'border border-gray-100'
							: 'border border-gray-300 text-center',
						'h-8 rounded-md w-full text-gray-900 focus:outline-none pl-2 text-center'
					)}
					name="fixed_charge"
					value={line.fixed_charge}
					onChange={(e) =>
						handleProductLineChange(index, 'fixed_charge', e.target.value)
					}
					disabled
				/>
			</td>
			<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
				<input
					id={`totalCost${index}`}
					type="text"
					className="pl-2 h-8 rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					name="totalCost"
					disabled
					value={numberFormat.format(Number(line.total_cost))}
				/>
			</td>
			<td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-12">
				<div className="flex w-full h-full text-center justify-center">
					<ConfirmationDialog
						icon="new"
						title="Addon Condition"
						body="Enter the labour conditions for this line item"
						cancelButtonText="Close"
						children={
							<TextArea
								title="Addon Conditions"
								id={`addon_condition${line.id}`}
								placeholder="Enter your conditions, saves automatically"
								handleChange={(e) =>
									handleProductLineChange(
										index,
										'addon_condition',
										e.target.value
									)
								}
								type="text"
								value={line.addon_condition}
								rows={10}
							/>
						}
						triggerButton={
							<button type="button">
								<PencilSquareIcon
									className="flex-shrink-0 h-4 w-4 text-blue-500"
									aria-hidden="true"
								/>
							</button>
						}
						confirmButton={<></>}
					/>
				</div>
			</td>
			<td className="px-2 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap">
				<div className="flex w-full h-full text-center justify-center">
					<button
						type="button"
						onClick={() => additionalLineHandlers.remove(index)}>
						<XCircleIcon
							className="flex-shrink-0 h-4 w-4 text-red-500"
							aria-hidden="true"
						/>
					</button>
				</div>
			</td>
		</tr>
	)
}
