import { Text, View } from '@react-pdf/renderer'
import { numberFormat } from 'utilities'
import { AddOnsFooterStyles as styles } from './styles'

interface FooterProps {
	items: any[]
}

export const QuoteTotalFooterRow = ({ items }: FooterProps) => {
	// Calculate totals

	const totalFee = items.filter((item) => item.is_total)
		.map((item) => Number(item.total))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	return (
		<>
			<View style={styles.row}>
				<Text style={styles.description}>Total (inc. GST)</Text>
				<Text style={styles.total}>{numberFormat.format(totalFee)}</Text>
			</View>
			{/* <View style={styles.row}>
				<Text style={styles.description}>Total (incl. GST)</Text>
				<Text style={styles.total_footer}>
					{numberFormat.format(weekFee + weekFee * 0.1)}
				</Text>
			</View> */}
		</>
	)
}
