import {
	IQuoteLabourLine,
	IQuoteZones,
	IRates,
	columnsQuoteLabourLines,
	IQuoteLabourLineFunctions,
	IQuoteForm,
} from 'models'
import { QuoteLabourLine } from './QuoteLabourLine'
import { PlusIcon } from '@heroicons/react/24/solid'
import { FormikProps } from 'formik'

interface QuoteLabourLinesProps {
	rates: IRates[]
	zones: IQuoteZones[]
	values: IQuoteLabourLine[]
	handlerFunctions: IQuoteLabourLineFunctions
	formik: FormikProps<IQuoteForm>
}

export const QuoteLabourLinesForm = ({
	rates,
	zones,
	values,
	handlerFunctions,
	formik
}: QuoteLabourLinesProps) => {
	return (
		<>
			<div className="w-full px-2">
				<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
					Labour Costs
				</h2>
				<table className="table-fixed w-full divide-gray-200">
					<thead className="bg-gray-100">
						<tr>
							<th className="w-56 text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
							Area
							</th>
							<th className="w-56 text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
							Type
							</th>
							<th className="text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
							Description
							</th>
							<th className="w-48 text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
							Total Hours
							</th>
							<th className="w-48 text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
							Labour Cost
							</th>
							<th className="w-24 text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
								Edit Labour Condition
							</th>
							{/* Additional Column to add de the delete line button */}
							<th className="w-16 text-center border border-gray-200 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
								Delete
							</th>
						</tr>
					</thead>
					<tbody>
						{values.map((quoteLine: IQuoteLabourLine, index: number) => (
							<QuoteLabourLine
								key={quoteLine.id}
								zones={zones}
								rates={rates}
								data={quoteLine}
								index={index}
								lineFunctionProvider={handlerFunctions}
								formik={formik}
							/>
						))}
					</tbody>
				</table>
				<div className="mt-6 mb-16">
					<button
						type="button"
						className="flex items-center"
						onClick={() => handlerFunctions.add()}>
						<PlusIcon
							className="flex-shrink-0 h-5 w-5 text-green-500"
							aria-hidden="true"
						/>
						<span className="ml-2 text-sm">Add Item</span>
					</button>
				</div>
			</div>
		</>
	)
}
