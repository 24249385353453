import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { quoteAdditionalLineFunctions } from './addonsHandlers'
import { ratesFn } from './ratesHandlers'
import { zonesFn } from './zonesHandlers'
import { useUpdateQuote } from './updateQuote'
import {
	IQuoteForm,
	IQuoteAdditionalLineFunctions,
	IQuoteHireLineFunctions,
	IRatesHandlers,
	IZonesHandlers,
	QuoteData,
	IQuoteAdditionalLine,
	IQuoteZones,
	IRates,
	QuoteDataToUpdate,
	IQuoteHireLine,
	IQuoteLabourLine,
	IQuoteLabourLineFunctions,
} from 'models/quotes.model'
import { useServiceRates } from './useServiceRates'
import { totalsFn } from './totalHandlers'
import { useCreateQuote } from './createQuote'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'config'
import { assignDataToEdit } from './assignDataToEdit'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { Roles } from 'models'
import { quoteHireLineFunctions, quoteLabourLineFunctions } from '.'

interface IUseFormHandler {
	quote_id?: number
	quote_data?: QuoteData
	quote_hire_lines?: IQuoteHireLine[]
	quote_labour_lines?: IQuoteLabourLine[]
	quote_addons?: IQuoteAdditionalLine[]
	quote_rates?: IRates[]
	quote_zones?: IQuoteZones[]
}

const itemsTabsAdmin = [
	{ label: 'Areas', id: 0 },
	{ label: 'Rates', id: 1 },
	{ label: 'Admin Rates', id: 2 },
]

const itemsTabsStandard = [
	{ label: 'Areas', id: 0 },
	{ label: 'Rates', id: 1 },
]

export const useFormHandler = ({
	quote_id,
	quote_data,
	quote_hire_lines,
	quote_labour_lines,
	quote_addons,
	quote_rates,
	quote_zones,
}: IUseFormHandler) => {
	const currentUserData = useSelector((store: AppStore) => store.user)

	let itemsTabs = []

	if (currentUserData?.userType === Roles.admin && !quote_id) {
		itemsTabs = itemsTabsAdmin
	} else {
		itemsTabs = itemsTabsStandard
	}

	const navigate = useNavigate()
	const { createQuote } = useCreateQuote()
	const {
		updateQuote,
		updateQuoteHireLines,
		updateQuoteLabourLines,
		updateQuoteAddOns,
		updateQuoteZones,
		updateQuoteRates,
	} = useUpdateQuote()

	const [editInfoLoaded, setEditInfoLoaded] = useState(false)
	const [backToMainTable, setBackToMainTable] = useState(false)

	const ratesInfo = useServiceRates()

	const validationSchema = Yup.object().shape({
		job_type: Yup.string().required('Job Type Is Required'),
		estimator: Yup.string().required('Estimator is Required'),
		client: Yup.string().required('Client is Required'),
		client_contact: Yup.string().required('Contact is Required'),
		variation_job_id: Yup.string().when('quote_type', (quote_type) => {
			if (quote_type[0] === 'Variation') {
				return Yup.string().required('Variation Job ID is required')
			}
			return Yup.string().nullable()
		}),
		zones: Yup.array().of(
			Yup.object().shape({
				zone_label: Yup.string().required('Area is required'),
			})
		),
		max_zones: Yup.number()
			.required('Max Areas is Required')
			.min(1, 'Max Areas is Required'),
		quote_hire_lines: Yup.array().of(
			Yup.object().shape({
				type: Yup.string().required('Type is Required'),
				zone_id: Yup.string().required('Area is Required'),
				description: Yup.string().required('Description is Required'),
				quantity: Yup.number().required('Quantity is Required'),
				length: Yup.number()
					.min(1, 'Length cannot be zero')
					.required('Length is Required'),
				height: Yup.number()
					.min(1, 'Height cannot be zero')
					.required('Height is Required'),
				width: Yup.number()
					.min(1, 'Width cannot be zero')
					.required('Width is Required'),
			})
		),
		quote_labour_lines: Yup.array().of(
			Yup.object().shape({
				type: Yup.string().required('Type is Required'),
				description: Yup.string().required('Description is Required'),
				zone_id: Yup.string().required('Area is Required'),
				labour_hours: Yup.number().required('Labour Hours is Required'),
			})
		),
		quote_addons: Yup.array().of(
			Yup.object().shape({
				type: Yup.string().required('Type is Required'),
				description: Yup.string().required('Description is Required'),
				duration_quantity: Yup.number().required('Duration is Required'),
			})
		),
	})

	const initialValues: IQuoteForm = {
		quote_type: 'New',
		job_type: '',
		variation_job_id: '',
		PO_Number: '',
		max_zones: 1,
		client: null,
		client_contact: null,
		quote_num: '',
		scope_of_work: '',
		estimator: null,

		fullAddress: '',
		street: '',
		country: '',
		street2: '',
		city: '',
		postal: '',

		quote_hire_lines: [],
		quote_labour_lines: [],

		quote_additional_lines: [],
		// TODO: Do this some other way that isn't UGLY
		additional_conditions: `MYSCAFFOLD TO SUPPLY DEFAULT VALUES FOR THIS SECTION`,

		totalLabourCost: 0,
		totalAdditionalItemsCost: 0,
		totalWeeklyHireCost: 0,
		total: 0,

		rates: ratesInfo.data,
		zones: [
			{
				id: null,
				zone_id: 1,
				zone_label: 'Default',
			},
		],
		file_1: '',
		file_2: '',
		file_3: '',
		file_4: '',
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			try {
				if (!editInfoLoaded) {
					// when the quote is new execute create a quote
					const resultQuote = await createQuote(values)
					if (resultQuote?.quote_id) {
						navigate(
							AppRoutes.privateRoutes.QuotesPDF.replace(
								':id',
								resultQuote.quote_id
							)
						)
					}
				} else {
					// when the quote is edited execute update a quote
					const quoteData: QuoteDataToUpdate = {
						job_type: values.job_type,
						quote_type: values.quote_type,
						max_zones: values.max_zones,
						client: Number(values.client),
						client_contact: values.client_contact,
						scope_of_work: values.scope_of_work,
						estimator: Number(values.estimator),

						fullAddress: values.fullAddress,
						street: values.street,
						country: values.country,
						street2: values.street2,
						city: values.city,
						postal: values.postal,

						additional_conditions: values.additional_conditions,

						totalLabourCost: Number(values.totalLabourCost),
						totalAdditionalItemsCost: Number(values.totalAdditionalItemsCost),
						totalWeeklyHireCost: Number(values.totalWeeklyHireCost),
						total: Number(values.total),
						variation_job_id: null,
						PO_Number: null,
						file_1: values.file_1,
						file_2: values.file_2,
						file_3: values.file_3,
						file_4: values.file_4,
					}
					updateQuote(quoteData, quote_id as number)
					updateQuoteAddOns(values.quote_additional_lines, quote_id as number)
					updateQuoteHireLines(values.quote_hire_lines, quote_id as number)
					updateQuoteLabourLines(values.quote_labour_lines, quote_id as number)
					updateQuoteRates(values.rates, quote_id as number)
					updateQuoteZones(values.zones, quote_id as number)

					if (backToMainTable) navigate(AppRoutes.privateRoutes.Quotes)
					else
						navigate(
							AppRoutes.privateRoutes.QuotesDetail.replace(
								':id',
								String(quote_id)
							)
						)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	// console.log('Errors', formik.errors)
	// console.log(formik.values.zones, 'zones')

	const quoteHireLineHandlers: IQuoteHireLineFunctions = {
		add: () => {
			const lines = quoteHireLineFunctions.calculate(
				formik.values.rates,
				quoteHireLineFunctions.add(formik.values.quote_hire_lines)
			)

			formik.setFieldValue('quote_hire_lines', lines)
		},
		remove: (index: number) => {
			const lines = quoteHireLineFunctions.remove(
				index,
				formik.values.quote_hire_lines
			)
			formik.setFieldValue('quote_hire_lines', lines)
		},
		update: async (index: number, field: string, value: string | number | boolean) => {
			let newQuoteLines = quoteHireLineFunctions.update(
				index,
				formik.values.quote_hire_lines,
				field,
				value
			)
			newQuoteLines = quoteHireLineFunctions.checkZones(
				formik.values.zones,
				newQuoteLines
			)
			newQuoteLines = quoteHireLineFunctions.calculate(
				formik.values.rates,
				newQuoteLines
			)
		
			formik.setFieldValue('quote_hire_lines', newQuoteLines)
		},
	}

	const quoteLabourLineHandlers: IQuoteLabourLineFunctions = {
		add: () => {
			const lines = quoteLabourLineFunctions.add(
				formik.values.quote_labour_lines
			)
			formik.setFieldValue('quote_labour_lines', lines)
		},
		remove: (index: number) => {
			const lines = quoteLabourLineFunctions.remove(
				index,
				formik.values.quote_labour_lines
			)
			formik.setFieldValue('quote_labour_lines', lines)
		},
		update: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteLabourLineFunctions.update(
				index,
				formik.values.quote_labour_lines,
				field,
				value
			)
			newQuoteLines = quoteLabourLineFunctions.checkZones(
				formik.values.zones,
				newQuoteLines
			)
			newQuoteLines = quoteLabourLineFunctions.calculate(
				formik.values.rates,
				newQuoteLines
			)

			formik.setFieldValue('quote_labour_lines', newQuoteLines)
		},
	}

	const quoteAdditionalLinesHandlers: IQuoteAdditionalLineFunctions = {
		add: () => {
			const newline = quoteAdditionalLineFunctions.add(
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', newline)
		},
		remove: (index: number) => {
			const lines = quoteAdditionalLineFunctions.remove(
				index,
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', lines)
		},
		update: (index: number, field: string, value: string | number) => {
			let lines = quoteAdditionalLineFunctions.update(
				index,
				formik.values.quote_additional_lines,
				field,
				value
			)
			lines = quoteAdditionalLineFunctions.calculate(lines, formik.values.rates)

			formik.setFieldValue('quote_additional_lines', lines)
		},
	}

	const ratesHandlers: IRatesHandlers = {
		addNewRate: () =>
			ratesFn.addNewRate(formik.values.rates, formik.setFieldValue),
		removeRate: (index: number) =>
			ratesFn.removeRate(index, formik.values.rates, formik.setFieldValue),
		updateRate: (index: number, field: string, value: string | number) =>
			ratesFn.updateRate(
				index,
				formik.values.rates,
				formik.setFieldValue,
				field,
				value
			),
	}

	const zonesHandlers: IZonesHandlers = {
		addNewZone: (zone_id: number) =>
			zonesFn.addNewZone(zone_id, formik.values.zones, formik.setFieldValue),
		removeZone: (index: number) =>
			zonesFn.removeZone(index, formik.values.zones, formik.setFieldValue),
		updateZone: (index: number, field: string, value: string | number) =>
			zonesFn.updateZone(
				index,
				formik.values.zones,
				formik.setFieldValue,
				field,
				value
			),
	}

	useEffect(() => {
		if (
			quote_data &&
			quote_hire_lines &&
			quote_labour_lines &&
			quote_addons &&
			quote_rates &&
			quote_zones &&
			!editInfoLoaded
		) {
			const newData = assignDataToEdit({
				quote_data,
				quote_hire_lines,
				quote_labour_lines,
				quote_addons,
				quote_rates,
				quote_zones,
			})
			setTimeout(() => {
				formik.setValues(newData)
				setEditInfoLoaded(true)
			}, 200)
		}
	}, [
		quote_data,
		quote_hire_lines,
		quote_labour_lines,
		quote_addons,
		quote_rates,
		quote_zones,
	])

	useEffect(() => {
		if (ratesInfo.data && !editInfoLoaded && !quote_id) {
			formik.setFieldValue('rates', ratesInfo.data)
		}
	}, [ratesInfo.data])

	// Check the zones when the max_zones changes
	useEffect(() => {
		zonesFn.checkZones(
			formik.values.max_zones,
			formik.values.zones,
			formik.setFieldValue
		)
	}, [formik.values.max_zones])

	// Check the quote lines when the zones, rates changes
	useEffect(() => {
		let newHireLines = quoteHireLineFunctions.checkZones(
			formik.values.zones,
			formik.values.quote_hire_lines
		)
		newHireLines = quoteHireLineFunctions.calculate(
			formik.values.rates,
			newHireLines
		)
		formik.setFieldValue('quote_hire_lines', newHireLines)

		let newLabourLines = quoteLabourLineFunctions.checkZones(
			formik.values.zones,
			formik.values.quote_labour_lines
		)
		newLabourLines = quoteLabourLineFunctions.calculate(
			formik.values.rates,
			newLabourLines
		)

		formik.setFieldValue('quote_labour_lines', newLabourLines)
	}, [formik.values.zones, formik.values.rates])

	// Functions to calculate the totals
	useEffect(() => {
		const totalLabourCost = totalsFn.calculatedLinePriceToTotal(
			formik.values.quote_labour_lines
		)

		const totalAdditionalItemsCost = totalsFn.calculateAdditionalTotal(
			formik.values.quote_additional_lines
		)

		const totalWeeklyHireCost = totalsFn.calculateWeekTotal(
			formik.values.quote_hire_lines
		)

		const total = totalLabourCost + totalAdditionalItemsCost

		formik.setFieldValue('totalLabourCost', totalLabourCost)
		formik.setFieldValue('totalAdditionalItemsCost', totalAdditionalItemsCost)
		formik.setFieldValue('totalWeeklyHireCost', totalWeeklyHireCost)
		formik.setFieldValue('total', total)
	}, [
		formik.values.quote_hire_lines,
		formik.values.quote_additional_lines,
		formik.values.quote_labour_lines,
	])

	return {
		formik,
		quoteHireLineHandlers,
		quoteLabourLineHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	}
}
