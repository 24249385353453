const quote_addons = [
	{
		id: 'additionalDesc',
		heading: 'Description',
	},
	{
		id: 'total',
		heading: 'Additional Costs',
	},
]

const quote_hire_lines = [
	{
		id: 'description',
		heading: 'Description',
	},
	{
		id: 'total',
		heading: 'Weekly Hire Fee',
	},
]

const quote_labour_lines = [
	{
		id: 'description',
		heading: 'Description',
	},
	{
		id: 'total',
		heading: 'Labour Costs',
	},
]

const quote_total = [
	{ id: 'additionalDesc', heading: 'Description' },
	{
		id: 'total',
		heading: 'Total',
	},
]

export const columns = {
	quote_addons,
	quote_hire_lines,
	quote_labour_lines,
	quote_total,
}

export enum ColumnTypes {
	quote_labour_lines = 'quote_labour_lines',
	quote_hire_lines = 'quote_hire_lines',
	quote_addons = 'quote_addons',
	quote_total = 'quote_total',
}
