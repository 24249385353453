import { useParams } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import { AppConfig } from 'config'

export const TimesheetLocationPage = () => {
	const { latitude, longitude } = useParams()
	const googleApiKey = AppConfig.GoogleMapsApiKey
	const defaultProps = {
		center: {
			lat: Number(latitude),
			lng: Number(longitude),
		},
		zoom: 16,
	}
	const MapComponent = (props: any) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="blue"
			className="w-10 h-10">
			<path
				fillRule="evenodd"
				d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
				clipRule="evenodd"
			/>
		</svg>
	)
	return (
		<div style={{ height: '100vh', width: '100%' }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: googleApiKey }}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				draggable={false}>
				<MapComponent lat={Number(latitude)} lng={Number(longitude)} />
			</GoogleMapReact>
		</div>
	)
}
