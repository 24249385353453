import { View, Text } from '@react-pdf/renderer'
import { IQuoteAdditionalLine } from 'models'
import { AddOnsFooterStyles as styles } from './styles'
import { numberFormat } from 'utilities'

interface AdditionalFooterRowProps {
	items: IQuoteAdditionalLine[]
}

export const AdditionalFooterRow = ({ items }: AdditionalFooterRowProps) => {
	const total = items
		.map((item) => item.total_cost)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	return (
		<>
			<View style={styles.row}>
				<Text style={styles.description}>Sub Total (ex. GST)</Text>
				<Text style={styles.total}>{numberFormat.format(total)}</Text>
			</View>
			{/* <View style={styles.row}>
				<Text style={styles.description}>Total (incl. GST)</Text>
				<Text style={styles.total}>
					{numberFormat.format(total + total * 0.1)}
				</Text>
			</View> */}
		</>
	)
}
