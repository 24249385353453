import { Spinner, Table } from 'common'
import columns from './Columns'
import { useNotesJobs } from 'services/notes'

interface JobsTableProps {
	id: string | number
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
}

export const JobsTable = ({
	id,
	setShowNotesForm,
	setNoteID,
}: JobsTableProps) => {
	const { data, isLoading } = useNotesJobs(id)
	console.log(data)
	let notes: any[] = []

	if (data)
	{
		for(const note of data){
			notes.push({
				...note,
				notes: note.notes.notes,
				file_name: note.notes.photos,
			})
		}
	}


	return (
		<div>
			<Table
				columns={columns({ setShowNotesForm, setNoteID })}
				data={notes}
				isLoading={isLoading}
				title="Job Notes & Files"
				setShowNotesForm={setShowNotesForm}
			/>
		</div>
	)
}
