export interface ITableColumn {
	title: string
	dataIndex: string
	key: string
	hidden: boolean
	enabled: boolean
}

export const columnsQuoteHireLines: ITableColumn[] = [
	{
		title: 'Areas',
		dataIndex: 'zone_id',
		key: 'zone_id',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Length',
		dataIndex: 'length',
		key: 'length',
		hidden: false,
		enabled: true,
	},
	
	{
		title: 'Width',
		dataIndex: 'width',
		key: 'width',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Height',
		dataIndex: 'height',
		key: 'height',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Multiplier',
		dataIndex: 'multiplier_factor',
		key: 'multiplier_factor',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Quantity',
		dataIndex: 'quantity',
		key: 'quantity',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Weekly Hire Fee',
		dataIndex: 'weekly_hire_fee',
		key: 'weekly_hire_fee',
		hidden: false,
		enabled: true,
	},
]

/*
	id: number | null
	zone_id: number | null
	zone_label: string
	type: string
	description: string
	length: number
	height: number
	width: number
	multiplier_factor: number
	quantity: number
	weekly_hire_fee: number
	*/

export const columnsQuoteLabourLines: ITableColumn[] = [
	{
		title: 'Area',
		dataIndex: 'zone_id',
		key: 'zone_id',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Total Hours',
		dataIndex: 'labour_hours',
		key: 'labour_hours',
		hidden: false,
		enabled: true,
	},
	{
		title: 'Cost',
		dataIndex: 'labour_cost',
		key: 'labour_cost',
		hidden: false,
		enabled: true,
	},
]

export const columnsQuoteAdditionalLines = [
	'Type',
	'Description',
	'Duration/Quantity',
	'Fixed Charge',
	'Total Cost',
]

export interface IQuoteForm {
	quote_type: string
	job_type: string
	variation_job_id: string | null
	PO_Number: string | null
	max_zones: number
	client: number | null
	client_contact: number | null
	quote_num?: string
	scope_of_work: string
	estimator: number | null

	fullAddress: string

	street: string
	country: string
	street2: string
	city: string
	postal: string

	quote_hire_lines: IQuoteHireLine[]
	quote_labour_lines: IQuoteLabourLine[]
	quote_additional_lines: IQuoteAdditionalLine[]

	additional_conditions: string

	totalLabourCost: number | string
	totalAdditionalItemsCost: number | string
	totalWeeklyHireCost: number | string
	total: number | string

	rates: IRates[]
	zones: IQuoteZones[]
	file_1: string
	file_2: string
	file_3: string
	file_4: string
}

export interface QuoteData {
	job_type: string
	id: number
	quote_num: string
	street: string
	street2: string
	city: string
	country: string
	status: string
	created_at: string
	updated_at: string
	max_zones: number
	scope_of_work: string
	postal: string
	totalWeeklyHireCost: number
	total: number
	additional_conditions: string
	client_contact: null | number
	estimator: number
	approved_by: null | number
	variation_job_id: null | string
	quote_type: string
	clientType: null | string
	PO_Number: null | string
	totalAdditionalItemsCost: number
	totalLabourCost: number
	emailStatus: string
	longitude: number
	latitude: number
	fullAddress: string
	client: number
	createdAt: string
	updatedAt: string
	file_1: string
	file_2: string
	file_3: string
	file_4: string
	clientData: {
		id: number
		client_name: string
		phone: string
		email: string
	}
	contactData: {
		id: number
		name: string
		phone: string
		email: string
	}
	estimatorData: {
		id: number
		staff_name: string
		email: string
		mobile: string
	}
}

export interface QuoteDataToUpdate {
	job_type: string
	street: string
	street2: string
	city: string
	max_zones: number
	scope_of_work: string
	postal: string
	country: string
	totalWeeklyHireCost: number
	total: number
	additional_conditions: string
	client_contact: null | number
	estimator: number
	variation_job_id: null | number
	quote_type: string
	PO_Number: null | string
	totalAdditionalItemsCost: number
	totalLabourCost: number
	fullAddress: string
	client: number
	file_1: string
	file_2: string
	file_3: string
	file_4: string
}

export interface IQuoteHireLineFunctions {
	add: () => void
	remove: (index: number) => void
	update: (
		index: number,
		field: string,
		value: string | number | boolean
	) => void
}

export interface IQuoteLabourLineFunctions {
	add: () => void
	remove: (index: number) => void
	update: (
		index: number,
		field: string,
		value: string | number | boolean
	) => void
}

export interface IQuoteAdditionalLineFunctions {
	add: () => void
	remove: (index: number) => void
	update: (
		index: number,
		field: string,
		value: string | number
	) => void
}

export interface IQuoteAdditionalLine {
	id: string | number | null
	type: string
	description: string
	duration_quantity: number
	fixed_charge: number
	total_cost: number
	addon_condition: string
}

export interface ILabourCondition {
	id: number
	condition: string
}

export interface IQuoteLine {
	id: number | null
	zone_id: number | null
	zone_label: string
	type: string
	description: string
	erect_hours: number
	dismantle_hours: number
	quantity: number
	total_days: number
	erect_and_dismantle: number
	percentage_weekly_hire_fee: number
	weekly_hire_fee: number
	total: number
	length: number
	height: number
	width: number
	total_dimensions: number
}

export interface IQuoteHireLine {
	id: number | null
	zone_id: number | null
	zone_label: string
	type: string
	description: string
	length: number
	height: number
	width: number
	multiplier_factor: number
	quantity: number
	weekly_hire_fee: number
	hire_condition: string
	is_manually_entered: boolean
}

export interface IQuoteLabourLine {
	id: number | null
	zone_id: number | null
	zone_label: string
	type: string
	description: string
	labour_hours: number
	labour_cost: number
	is_manually_entered: boolean
	labour_condition: string
}

export interface IRates {
	id: number | null
	type: string
	service: string
	fee: number
}

export interface IRatesHandlers {
	addNewRate: () => void
	removeRate: (index: number) => void
	updateRate: (index: number, field: string, value: string | number) => void
}

export interface IQuoteZones {
	id: string | number | null
	zone_id: string | number
	zone_label: string
	quote_id?: string
}

export interface IZonesHandlers {
	addNewZone: (zone_id: number) => void
	removeZone: (index: number) => void
	updateZone: (index: number, field: string, value: string | number) => void
}
