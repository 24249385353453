import { Text, View } from '@react-pdf/renderer'
import { numberFormat } from 'utilities'
import { AddOnsFooterStyles as styles } from './styles'
import { IQuoteLine } from 'models'

interface FooterProps {
	items: IQuoteLine[]
}

export const FooterQuoteHireLines = ({ items }: FooterProps) => {
	// Calculate totals
	const erect = items
		.map((item) => Number(item.erect_and_dismantle))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	const weekFee = items
		.map((item) => Number(item.weekly_hire_fee))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)

	return (
		<>
			<View style={styles.row}>
				<Text style={styles.description}>Sub Total (ex. GST)</Text>
				<Text style={styles.total}>{numberFormat.format(weekFee)}</Text>
			</View>
			{/* <View style={styles.row}>
				<Text style={styles.description}>Total (incl. GST)</Text>
				<Text style={styles.total_footer}>
					{numberFormat.format(weekFee + weekFee * 0.1)}
				</Text>
			</View> */}
		</>
	)
}
