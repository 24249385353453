import { Document, Page, Text, View, Image } from '@react-pdf/renderer'
import {
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLine,
	IQuoteHireLine,
	IQuoteLabourLine,
} from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: {
		quote_hire_lines: IQuoteHireLine[]
		quote_labour_lines: IQuoteLabourLine[]
	}
	quote_addons: IQuoteAdditionalLine[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	const total_data: {
		zone_label: string
		description: string
		is_total?: boolean
		total: number
	}[] = []
	const hire_total = quote_lines.quote_hire_lines
		.map((item) => Number(item.weekly_hire_fee))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	const labour_total = quote_lines.quote_labour_lines
		.map((item) => Number(item.labour_cost))
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	const addons_total = quote_addons
		.map((item) => item.total_cost)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
	total_data.push(
		// { zone_label: 'Hire', description: 'Cost', total: hire_total },
		// { zone_label: 'Hire', description: 'GST', total: hire_total * 0.1 },
		{
			zone_label: 'Hire',
			description: 'Total (inc. GST)',
			total: hire_total + hire_total * 0.1,
			is_total: true,
		},
		// {
		// 	zone_label: 'Labour',
		// 	description: 'Cost',
		// 	total: labour_total,
		// },
		// {
		// 	zone_label: 'Labour',
		// 	description: 'GST',
		// 	total: labour_total * 0.1,
		// },
		{
			zone_label: 'Labour',
			description: 'Total (inc. GST)',
			total: labour_total + labour_total * 0.1,
			is_total: true,
		},
		// {
		// 	zone_label: 'Additional Items',
		// 	description: 'Cost',
		// 	total: addons_total,
		// },
		// {
		// 	zone_label: 'Additional Items',
		// 	description: 'GST',
		// 	total: addons_total * 0.1,
		// },
		{
			zone_label: 'Additional Items',
			description: 'Total (inc. GST)',
			total: addons_total + addons_total * 0.1,
			is_total: true,
		}
	)

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Description description={quote_data.scope_of_work} />
				<View>
					<Text style={styles.heading}>Hire</Text>
					<Table
						type={ColumnTypes.quote_hire_lines}
						data={quote_lines.quote_hire_lines}
					/>
				</View>
				<View>
					<Text style={styles.heading}>Labour</Text>
					<Table
						type={ColumnTypes.quote_labour_lines}
						data={quote_lines.quote_labour_lines}
					/>
				</View>
				<View>
					<Text style={styles.heading}>Additional Items</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>

				<View>
					<Text style={styles.heading}>Totals</Text>
					<Table type={ColumnTypes.quote_total} data={total_data} />
				</View>

				{/* <Text style={styles.subTextBoldRed}>All prices exclude GST</Text> */}

				{quote_data.additional_conditions?.length > 0 && (
					<View>
						<Text style={styles.heading}>Additional Conditions</Text>
						<Text style={{ ...styles.subTextMargin, marginLeft: 2 }}>
							{quote_data.additional_conditions}
						</Text>
					</View>
				)}
			</Page>
			{(quote_lines.quote_labour_lines.filter(
				(line) =>
					line.labour_condition != '' &&
					line.labour_condition !== null &&
					line.description != '' &&
					line.zone_id != null
			).length > 0 ||
				quote_addons.filter(
					(line) =>
						line.addon_condition != '' &&
						line.addon_condition !== null &&
						line.description != '' &&
						line.type != null
				).length > 0 ||
				quote_lines.quote_hire_lines.filter(
					(line) =>
						line.hire_condition != '' &&
						line.hire_condition !== null &&
						line.description != '' &&
						line.zone_id != null
				).length > 0) && (
				<Page size="A4" style={styles.page}>
					<Heading />
					{quote_lines.quote_labour_lines.filter(
						(line) =>
							line.labour_condition != '' &&
							line.labour_condition !== null &&
							line.description != '' &&
							line.zone_id != null
					).length > 0 && (
						<View>
							<Text style={styles.heading}>Labour Conditions</Text>
							<View style={{ flexDirection: 'column' }}>
								{quote_lines.quote_labour_lines
									.filter(
										(line) =>
											line.labour_condition != '' &&
											line.labour_condition !== null &&
											line.description != '' &&
											line.zone_id != null
									)
									.map((line) => (
										<View style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											<Text style={{ fontWeight: 'bold' }}>
												Area: {line.zone_label}
											</Text>
											<Text>{line.labour_condition}</Text>
										</View>
									))}
							</View>
						</View>
					)}
					{quote_lines.quote_hire_lines.filter(
						(line) =>
							line.hire_condition != '' &&
							line.hire_condition !== null &&
							line.description != '' &&
							line.zone_id != null
					).length > 0 && (
						<View>
							<Text style={styles.heading}>Hire Conditions</Text>
							<View style={{ flexDirection: 'column' }}>
								{quote_lines.quote_hire_lines
									.filter(
										(line) =>
											line.hire_condition !== '' &&
											line.hire_condition !== null &&
											line.description != '' &&
											line.zone_id != null
									)
									.map((line) => (
										<View style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											<Text style={{ fontWeight: 'bold' }}>
												Area: {line.zone_label}
											</Text>
											<Text>{line.hire_condition}</Text>
										</View>
									))}
							</View>
						</View>
					)}
					{quote_addons.filter(
						(line) =>
							line.addon_condition != '' &&
							line.addon_condition !== null &&
							line.description != '' &&
							line.type != null
					).length > 0 && (
						<View>
							<Text style={styles.heading}>Additional Item Conditions</Text>
							<View style={{ flexDirection: 'column' }}>
								{quote_addons
									.filter(
										(line) =>
											line.addon_condition != '' &&
											line.addon_condition !== null &&
											line.description != '' &&
											line.type != null
									)
									.map((line) => (
										<View style={{ ...styles.subTextMargin, marginLeft: 2 }}>
											<Text style={{ fontWeight: 'bold' }}>
												Additional Item: {line.description}
											</Text>
											<Text>{line.addon_condition}</Text>
										</View>
									))}
							</View>
						</View>
					)}
				</Page>
			)}
			{(quote_data.file_1 != '' ||
				quote_data.file_2 != '' ||
				quote_data.file_3 != '' ||
				quote_data.file_4 != '') && (
				<Page size="A4" style={styles.page_1}>
					{quote_data?.file_1 != null && quote_data?.file_1 != '' ? (
						<View key={'image_x_1'} style={styles.quadrant}>
							<Image src={quote_data?.file_1} style={styles.image} />
						</View>
					) : null}
					{quote_data?.file_2 != null && quote_data?.file_2 != '' ? (
						<View key={'image_x_2'} style={styles.quadrant}>
							<Image src={quote_data?.file_2} style={styles.image} />
						</View>
					) : null}
					{quote_data?.file_3 != null && quote_data?.file_3 != '' ? (
						<View key={'image_x_3'} style={styles.quadrant}>
							<Image src={quote_data?.file_3} style={styles.image} />
						</View>
					) : null}
					{quote_data?.file_4 != null && quote_data?.file_4 != '' ? (
						<View key={'image_x_4'} style={styles.quadrant}>
							<Image src={quote_data?.file_4} style={styles.image} />
						</View>
					) : null}
				</Page>
			)}
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.heading}>Terms and Conditions</Text>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Invoices will be issued under the ‘Building and Construction
						Industry Security of Payments Act 1999 NSW’.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						MYSCAFFOLD cannot be held responsible for delays in delivery, erection,
						relocation, dismantle and collection due to inclement weather.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						ScaffM8 reserves the right on entry to recover materials from site if
						any invoices remain unpaid exceeding 45 days from date of invoice.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						The Customer is responsible for ensuring that the roof and balconies
						on which the swing stages or scaffolding are to be supported have
						adequate load bearing capacity to support the loads due to the
						scaffolding.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						If required MYSCAFFOLD can advise on the appropriate loads but subject to
						an engineering fee.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						If an engineer’s certificate is required a minimum fee of $1250 is
						applicable.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						The customer is responsible for electrical supply. (single phase 240
						volt/15 amp per stage standalone circuit)
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						The customer is to supply their own P.P.E i.e., harnesses, first aid
						kit etc.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						It is the customer's responsibility to ensure all operators of the
						swinging stage are present on completion of erection of the swing
						stage and are to be inducted on the correct use of the stage. (This
						includes the daily completion of logbooks, and ensuring covers are
						always on the winch motors and dizzy boxes).
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Any licenses or permission necessary are to be obtained by the
						customer (Including permission to encroach on neighboring building's
						air space where necessary) are required before commencement of work.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						This price does not include hoardings, overhead protection or shade
						cloth unless stated in the quotation.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						The hire rate incorporates downtime during relocations of equipment,
						public holidays, RDO's, productivity days and inclement weather. (NO
						RAIN DAYS)
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						MYSCAFFOLD reserves the right to collect any equipment from the site if
						client accounts are in arrears or unsatisfactory. Charges will be
						forwarded to the client for dismantlement and transport costs.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						All prices on this quotation are based on work to be carried out
						during normal working hours (Mon - Fri 7.00am - 3.30pm)
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						This quote is based on the free use of cranes or other hoisting
						equipment for the depositing and removal of our scaffolding at
						various levels as required on site or otherwise stated in quotation.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						The customer is responsible for advising the MYSCAFFOLD representative or
						office on completion of the.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						All materials on a ‘dry hire’ basis are to be returned within 2
						working days of obtaining an off-hire number, failure to do so will
						result in continuing hire charges.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Please note that the Customer is liable for the cost of replacing
						any unreturned equipment as well as the cost of repairing any
						damaged equipment. Equipment must be returned to the same condition
						as it was received, including cleaning. Equipment returned that
						requires cleaning will be charged to the customer.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						To avoid disputes about verbal orders or variations, all additions
						or variations must be in writing accompanied by a signed company
						purchase order or authority before any work is conducted.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Any monthly inspections on scaffold or additional swing stage
						inductions will be charged out at a minimum of 4-man hrs. ($440.00)
						please factor this in your tender.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						If alterations to scaffold or swing stages are required, the
						following rates will apply.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Scaffold Rates: Minimum 4hrs @ $95.00 per hour normal time, $135.00
						per hour overtime rates.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Swing stage rates: Minimum 4hrs per man @ 110.00 per hour normal
						time, $170.00 per hour overtime rates
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Night shift Rates: Minimum 4hrs per man @ $170.00 per hour
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Delivery/ pick-ups - A representative may be appointed by the
						customer for the sole purpose of checking the quantity of material
						delivered and the time of delivery. If no representative is
						provided, the customer agrees that MYSCAFFOLD shall be able to affect
						delivery and/or pick up of equipment at its absolute discretion
						without the necessity for a representative of the customer to be
						present. Conclusive proof of receipt of the quantity date and
						description of equipment delivered, and the delivery of the
						equipment in good condition with be evidenced by the delivery
						docket.
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Customers may use the equipment only on the site specified in
						customers’ orders and may not transfer the equipment to any other
						site without MYSCAFFOLD’s approval.{' '}
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						MYSCAFFOLD reserves the right to charge for the cost of equipment used or
						purchased and the cost of labour and transport incurred for any
						order cancelled or altered by customer after the receipt of an
						authorized purchase order.{' '}
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						MYSCAFFOLD warrants that the equipment will be of merchantable quality
						and free from defects. All other warranties, expressed or implied,
						are hereby excluded.{' '}
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						MYSCAFFOLD shall under no circumstances be liable to the customer in
						connection with the supply of the equipment and services for any
						loss of use, loss of profit or consequential loss, whether arising
						in negligence or otherwise. To the extent permitted by law, MYSCAFFOLD's
						liability to the customer is limited to the re-supply of the
						equipment or services.{' '}
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						All electrical equipment when delivered to site will have
						certification and tagging attached. All scaffolding when installed
						will be handed over in accordance with the relevant Australian
						standards. All subsequent monthly tagging and or inspections will be
						the responsibility of the hirer unless prior arrangements have been
						made.{' '}
					</Text>
				</View>
				<View style={{ flexDirection: 'row', marginBottom: 1 }}>
					<Text style={{ fontSize: 10, marginLeft: 0, marginRight: 8 }}>
						{'\u2022'}
					</Text>
					<Text style={styles.subText}>
						Customers are responsible for the structural load capabilities of
						which the scaffold will be based upon.{' '}
					</Text>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Definitions
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.1</Text>
						<Text style={styles.subText}>
							“Contract” means the terms and conditions contained herein,
							together with any Quotation, hire form, invoice or other document
							or amendments expressed to be supplemental to this Contract.{' '}
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.2</Text>
						<Text style={styles.subText}>
							“ScaffM8” means ScaffM8 Pty Ltd T/A ScaffM8, its
							successors and assigns or any person acting on behalf of and with
							the authority of ScaffM8 Pty Ltd T/A ScaffM8.{' '}
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.3</Text>
						<Text style={styles.subText}>
							“Client” means the person/s, entities or any person acting on
							behalf of and with the authority of the Client requesting ScaffM8
							Access to provide the services as specified in any proposal,
							quotation, order, invoice or other documentation, and: {'\n'}(a)
							if there is more than one Client, is a reference to each Client
							jointly and severally; and {'\n'}(b) if the Client is a
							partnership, it shall bind each partner jointly and severally; and{' '}
							{'\n'}(c) if the Client is a part of a Trust, shall be bound in
							their capacity as a trustee; and {'\n'}(d) includes the Client’s
							executors, administrators, successors and permitted assigns.
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.4</Text>
						<Text style={styles.subText}>
							“Equipment” means all Equipment (including any accessories)
							supplied on hire by ScaffM8 to the Client (and where the
							context permits shall include any incidental supply of services).
							The Equipment shall be as described on the invoices, quotation,
							authority to hire, or any other work authorization forms as
							provided by ScaffM8 to the Client.
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.5</Text>
						<Text style={styles.subText}>
							“Minimum Hire Period” means the Minimum Hire Period as described
							on the invoices, quotation, authority to hire, or any other forms
							as provided by ScaffM8 to the Client.{' '}
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.6</Text>
						<Text style={styles.subText}>
							"Confidential Information” means information of a confidential
							nature whether oral, written or in electronic form including, but
							not limited to, this Contract, either party’s intellectual
							property, operational information, know-how, trade secrets,
							financial and commercial affairs, contracts, client information
							(including but not limited to, “Personal Information” such as:
							name, address, D.O.B, occupation, driver’s license details,
							electronic contact (email, Facebook or Twitter details), medical
							insurance details or next of kin and other contact information
							(where applicable), previous credit applications, credit history)
							and pricing details.{' '}
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.7</Text>
						<Text style={styles.subText}>
							“Cookies” means small files which are stored on a user’s computer.
							They are designed to hold a modest amount of data (including
							Personal Information) specific to a particular client and website
							and can be accessed either by the web server or the client’s
							computer.
							<Text style={{ fontWeight: 'semibold' }}>
								If the Client does not wish to allow Cookies to operate in the
								background when ordering from the website, then the Client shall
								have the right to enable / disable the Cookies first by
								selecting the option to enable / disable provided on the
								website, prior to ordering Equipment via the website.
							</Text>
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.8</Text>
						<Text style={styles.subText}>
							“Price” means the cost of the hire of the Equipment (plus any GST
							where applicable) as agreed between ScaffM8 and the Client
							subject to clause 5 of this Contract.{' '}
						</Text>
					</View>

					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>1.9</Text>
						<Text style={styles.subText}>
							“GST” means Goods and Services Tax as defined within the “A New
							Tax System (Goods and Services Tax) Act 1999” (Cth).{' '}
						</Text>
					</View>
				</View>
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>2.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Acceptance
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>2.1</Text>
						<Text style={styles.subText}>
							The Client is taken to have exclusively accepted and is
							immediately bound, jointly and severally, by these terms and
							conditions if the Client places an order for, or accepts Delivery
							of, the Equipment.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>2.2</Text>
						<Text style={styles.subText}>
							In the event of any inconsistency between the terms and conditions
							of this Contract and any other prior document or schedule that the
							parties have entered into, the terms of this Contract shall
							prevail.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>2.3</Text>
						<Text style={styles.subText}>
							Any amendment to the terms and conditions contained in this
							Contract may only be amended in writing by the consent of both
							parties.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>2.4</Text>
						<Text style={styles.subText}>
							The Client acknowledges and accepts that:{'\n'}
							(a) the hire of Equipment on credit shall not take effect until
							the Client has completed a credit application with ScaffM8
							and it has been approved with a credit limit established for the
							account.{'\n'}
							(b) in the event that the hire of Equipment request exceeds the
							Clients credit limit and/or the account exceeds the payment terms,
							ScaffM8 reserves the right to refuse Delivery.{'\n'}
							(c) the erection of scaffolding shall only take place during
							business hours of Monday-Friday 7.00am to 3.30pm, unless agreed
							between both parties.{'\n'}
							(d) the product supply of shrink wrap offers a manufacturer’s
							guarantee of UV protection and can withstand up to the
							manufacturers specified tolerance and/or Bureau of Meteorology
							definition regarding gale force winds, being winds of up to
							63-75kph; and{'\n'}
							(e) that when the contract period for hire completed under this
							contract and payment is not forthcoming when due and payable, then
							ScaffM8 reserves the right at their discretion to leave the
							scaffolding erected until such time as payment effected and the
							Client shall be liable for all loss hire charges in accordance
							with clause 13.5(e);{'\n'}
							(f) no cantilever scaffolds are included in the Price, unless
							otherwise agreed.{'\n'}
							(g) variations to the contract requested by the Client that are
							subject to labour charges, shall be at ScaffM8’ current
							hourly rate and any applicable site allowances, unless otherwise
							stated at the time of the variation request.{'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>2.5</Text>
						<Text style={styles.subText}>
							Electronic signatures shall be deemed to be accepted by either
							party providing that the parties have complied with Section 9) of
							the Electronic Transactions Act 2000 or any other applicable
							provisions of that Act or any Regulations referred to in that Act.{' '}
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>3.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Errors and Omissions
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>3.1</Text>
						<Text style={styles.subText}>
							The Client acknowledges and accepts that ScaffM8 shall,
							without prejudice, accept no liability in respect of any alleged
							or actual error(s) and/or omission(s):{'\n'}
							(a) resulting from an inadvertent mistake made by ScaffM8 in
							the formation and/or administration of this Contract; and/or{'\n'}
							(b) contained in/omitted from any literature (hard copy and/or
							electronic) supplied by ScaffM8 in respect of the Equipment
							hire and/or/services. {'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>3.2</Text>
						<Text style={styles.subText}>
							In the event such an error and/or omission occurs in accordance
							with clause 3.1, and is not attributable to the negligence and/or
							willful misconduct of ScaffM8; the Client shall not be
							entitled to treat this Contract as repudiated nor render it
							invalid.
						</Text>
					</View>
				</View>
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>4.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Change in Control
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>4.1</Text>
						<Text style={styles.subText}>
							The Client shall give ScaffM8 not less than fourteen (14)
							days prior written notice of any proposed change of ownership of
							the Client and/or any other change in the Client’s details
							(including but not limited to, changes in the Client’s name,
							address, contact phone or fax number/s, change of trustees or
							business practice). The Client shall be liable for any loss
							incurred by ScaffM8 as a result of the Client’s failure to
							comply with this clause.
						</Text>
					</View>
				</View>
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Price and Payment
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.1</Text>
						<Text style={styles.subText}>
							At ScaffM8’ sole discretion the Price shall be either.{'\n'}
							(a) as indicated on invoices provided by ScaffM8 to the
							Client in respect of Equipment supplied on hire; or{'\n'}(b) ScaffM8
							Access’ current Price, at the date of Delivery of the Equipment,
							according to ScaffM8’ current price list; or{'\n'}(c) ScaffM8
							Access’ quoted Price (subject to clause 5.2 which shall be binding
							upon ScaffM8 provided that the Client shall accept in writing
							ScaffM8’ quotation within ninety (90) days.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.2</Text>
						<Text style={styles.subText}>
							ScaffM8 reserves the right to change the Price:{'\n'}(a) if a
							variation to the Equipment which is to be supplied is requested.
							{'\n'}(b) if a variation to the Services originally scheduled
							(including any applicable plans or specifications) is requested.
							{'\n'}(c) if variations which are beyond ScaffM8’ control
							occur (such as the cost of materials, labour, taxes, levies,
							duties, insurance and/or freight etc.){'\n'}(d) where additional
							Equipment/Services are required due to the discovery of hidden or
							unidentifiable difficulties (including, but not limited to, poor
							weather/environmental conditions, limitations to accessing the
							site, availability of machinery, safety considerations,
							prerequisite work by any third party not being completed
							substantially or at all etc.) which are only discovered on
							commencement of the Services.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.3</Text>
						<Text style={styles.subText}>
							Variations will be charged for on the basis of ScaffM8’
							quotation, and will be detailed in writing, and shown as
							variations on ScaffM8’ invoice. The Client shall be required
							to respond to any variation submitted by ScaffM8 within ten
							(10) working days. Failure to do so will entitle ScaffM8 to
							add the cost of the variation to the Price. Payment for all
							variations must be made in full at the time of their completion.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.4</Text>
						<Text style={styles.subText}>
							At ScaffM8’ sole discretion, a deposit (in the form of a
							bond) shall be required at the commencement of this Contract,
							which shall be refunded to the Client by within thirty (30) days
							of the return of the Equipment, provided that the Client has
							complied with their obligations hereunder. The deposit may be used
							to offset any applicable charges payable by the Client under
							clause 17.5, and any outstanding balance thereof shall be due as
							per clause 5.5.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.5</Text>
						<Text style={styles.subText}>
							Time for payment for the Equipment being of the essence, the Price
							will be payable by the Client on the date/s determined by ScaffM8
							Access, which may be:{'\n'}(a) by way of instalments/progress
							payments in accordance with ScaffM8’ payment schedule.{'\n'}
							(b) thirty (30) days following the date specified on any invoice
							or other form as being the date for payment; or.{'\n'}(c) the date
							specified on any invoice or other form as being the date for
							payment; or{'\n'}(d) failing any notice to the contrary, the date
							which is seven (7) days following the date of any invoice given to
							the Client by ScaffM8.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.6</Text>
						<Text style={styles.subText}>
							Payment may be made by cheque, bank cheque, or by any other method
							as agreed to between the Client and ScaffM8.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.7</Text>
						<Text style={styles.subText}>
							ScaffM8 may in its discretion allocate any payment received
							from the Client towards any invoice that ScaffM8 determines
							and may do so at the time of receipt or at any time afterwards. On
							any default by the Client ScaffM8 may re-allocate any
							payments previously received and allocated. In the absence of any
							payment allocation by ScaffM8, payment will be deemed to be
							allocated in such manner as preserves the maximum value of ScaffM8
							Access’ Purchase Money Security Interest (as defined in the PPSA)
							in the Equipment.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.8</Text>
						<Text style={styles.subText}>
							The Client shall not be entitled to set off against, or deduct
							from the Price, any sums owed or claimed to be owed to the Client
							by ScaffM8 nor to withhold payment of any invoice because
							part of that invoice is in dispute.
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.9</Text>
						<Text style={styles.subText}>
							The Client acknowledges and agrees that the Client’s obligations
							to ScaffM8 for the supply of Equipment on hire shall not
							cease until:{'\n'}(a) the Client has paid ScaffM8 all amounts
							owing for the hire of the Equipment; and{'\n'}(b) the Client has
							met all other obligations due by the Client to ScaffM8 in
							respect of all contracts between ScaffM8 and the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.10</Text>
						<Text style={styles.subText}>
							Unless otherwise stated the Price does not include GST. In
							addition to the Price the Client must pay to ScaffM8 an
							amount equal to any GST ScaffM8 must pay for any supply by
							ScaffM8 under this or any other agreement for the hire of the
							Equipment. The Client must pay GST, without deduction or set off
							of any other amounts, at the same time and on the same basis as
							the Client pays the Price. In addition, the Client must pay any
							other taxes and duties that may be applicable in addition to the
							Price except where they are expressly included in the Price.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>5.11</Text>
						<Text style={styles.subText}>
							Receipt by ScaffM8 of any form of payment other than cash
							shall not be deemed to be payment until that form of payment has
							been honored, cleared or recognized.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>6.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Hire Period
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>6.1</Text>
						<Text style={styles.subText}>
							Hire charges shall commence from the time the scaffolding is
							erected and certified for use by the Client and shall continue
							until the Client advises that the scaffold is “Off hired”. In the
							case where the Client revokes the off-hire notice or has continued
							to use the scaffolding after the off hiring period has occurred
							then the hire charges shall continue without interruption until
							the actual end of the Hire Period or until the expiry of the
							Minimum Hire Period, whichever last occurs.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>6.2</Text>
						<Text style={styles.subText}>
							The date upon which the Client advises of termination shall in all
							cases be treated as a full day’s hire.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>6.3</Text>
						<Text style={styles.subText}>
							All hire charges are for a minimum of one (1) week hire and
							thereafter hire is calculated on five (5) working days per week.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>6.4</Text>
						<Text style={styles.subText}>
							No allowance whatever can be made for time during which the
							Equipment is not in use for any reason, unless ScaffM8
							confirms special prior arrangements in writing. In the event of
							Equipment breakdown provided the Client notifies ScaffM8
							immediately, hiring charges will not be payable during the time
							the Equipment is not working, unless the condition is due to
							negligence or misuse on the part of or attributable to the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>7.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Delivery
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>7.1</Text>
						<Text style={styles.subText}>
							Delivery (
							<Text style={{ fontWeight: 'semibold' }}>“Delivery”</Text>) of the
							Equipment is taken to occur at the time that ScaffM8 (or
							ScaffM8’ nominated carrier) delivers the Equipment to the
							Client’s nominated address even if the Client is not present at
							the address.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>7.2</Text>
						<Text style={styles.subText}>
							At ScaffM8’ sole discretion the cost of Delivery is in
							addition to the Price. .
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>7.3</Text>
						<Text style={styles.subText}>
							ScaffM8 may deliver the Equipment by separate instalments.
							Each separate instalment shall be invoiced and paid for in
							accordance with the provisions in these terms and conditions.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>7.4</Text>
						<Text style={styles.subText}>
							Any time specified by ScaffM8 for Delivery of the Equipment
							is an estimate only and ScaffM8 will not be liable for any
							loss or damage incurred by the Client as a result of Delivery
							being late. However, both parties agree that they shall make every
							endeavor to enable the Equipment to be supplied at the time and
							place as was arranged between both parties. In the event that
							ScaffM8 is unable to supply the Equipment as agreed solely
							due to any action or inaction of the Client, then ScaffM8
							shall be entitled to charge a reasonable fee for re-supplying the
							Equipment at a later time and date, and/or for storage of the
							Equipment.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Risk
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.1</Text>
						<Text style={styles.subText}>
							ScaffM8 retains property in the Equipment nonetheless all
							risk for the Equipment passes to the Client on Delivery.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.2</Text>
						<Text style={styles.subText}>
							The Client accepts full responsibility for the safekeeping of the
							Equipment and indemnifies ScaffM8 for all loss, theft, or
							damage to the Equipment howsoever caused and without limiting the
							generality of the foregoing whether or not such loss, theft, or
							damage is attributable to any negligence, failure, or omission of
							the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.3</Text>
						<Text style={styles.subText}>
							The Client will insure, or self-insure, ScaffM8’ interest in
							the Equipment against physical loss or damage including, but not
							limited to, the perils of accident, fire, theft and burglary and
							all other usual risks and will affect adequate Public Liability
							Insurance covering any loss, damage or injury to property arising
							out of the Equipment. Further the Client will not use the
							Equipment nor permit it to be used in such a manner as would
							permit an insurer to decline any claim.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.4</Text>
						<Text style={styles.subText}>
							The Client accepts full responsibility for and shall keep ScaffM8
							Access indemnified against all liability in respect of all
							actions, proceedings, claims, damages, costs and expenses in
							respect of any injury to persons, damage to property, or otherwise
							arising out of the use of the Equipment during the hire period and
							whether or not arising from any negligence, failure or omission of
							the Client or any other persons.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.5</Text>
						<Text style={styles.subText}>
							ScaffM8 shall be entitled to rely on the accuracy of any
							plans, specifications and other information provided by the Client
							for the purposes of estimating quantities and type of Equipment
							required. The Client acknowledges and agrees that in the event
							that any of this information provided by the Client is inaccurate,
							ScaffM8 accepts no responsibility for any loss, damages, or
							costs however resulting from these inaccurate plans,
							specifications or other information.
						</Text>
					</View>
				</View>
			</Page>
			// FIT AS MANY "SECTIONS" PER PAGE AS POSSIBLE
			<Page size="A4" style={styles.page}>
				<Heading />
				// ONE SECTION
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.6</Text>
						<Text style={styles.subText}>
							In the event of damage to a building as a result of any action by
							ScaffM8’ employees or contractors, ScaffM8 must be
							informed in order that ScaffM8 may inspect such damage before
							any agreement to rectification costs can be accepted.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>8.7</Text>
						<Text style={styles.subText}>
							Any Equipment that is erected or dismantled by ScaffM8’
							trained and licensed employees or contractors shall be as per the
							manufacturers and New Zealand Codes of Practice requirements. The
							Client acknowledges and accepts that ScaffM8 shall not be
							liable in any way for any claim made in connection with the
							erection and dismantling of the Equipment by non-ScaffM8
							employees or contractors.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Access and Installation
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.1</Text>
						<Text style={styles.subText}>
							The Client shall ensure that ScaffM8 has clear and free
							access to the nominated delivery address to enable ScaffM8 to
							install and/or dismantle the Equipment, and ScaffM8 agrees at
							the time of completion thereof to carry out in a reasonable way
							the clean-up of the site where scaffolding was installed.
							Furthermore, it is acknowledged that it is unreasonable to expect
							the clean-up to restore the property to its pre-existing
							condition, especially in the event where existing grass has died
							off due to the covering of base blocks to establish foundation
							support for the Equipment, such damage will remain the Client’s
							responsibility.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.2</Text>
						<Text style={styles.subText}>
							The Client acknowledges and accepts that where the Equipment is
							required to be tied to the building structure, with ties at each
							scaffold lift, using either bolts, pins or screw eyes, the
							subsequent repairs of theses holes and/or repainting shall be the
							responsibility of the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.3</Text>
						<Text style={styles.subText}>
							The quotation is based upon wire ties and/or bolted scaffold ties
							to secure the Equipment to the building and the construction
							procedures must allow for these ties to remain in position whilst
							the Equipment is in use.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.4</Text>
						<Text style={styles.subText}>
							It shall be the Client’s responsibility to ensure that where
							Equipment is installed on concreted areas, patios, driveways or
							timber decks that suitable coverings are put in place to help
							prevent any marking/staining of the finished product. ScaffM8
							shall not be liable for any loss or damage to the site (including,
							without limitation, damage to pathways, driveways and concreted or
							paved or grassed areas) unless due to the negligence of ScaffM8
							Access.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.5</Text>
						<Text style={styles.subText}>
							The Client shall ensure that the nominated delivery address is
							cleared and ready for installation of the Equipment prior to
							delivery of the Equipment, and that the foundations upon which
							ScaffM8 is to install the Equipment is sufficiently firm and
							otherwise suitable to safely carry the structure and the load to
							be put on it without subsidence. The Client will be liable to
							ScaffM8 for any loss, costs or damages which ScaffM8 may
							suffer or incur by reason of the Client’s failure to carry out its
							obligations hereunder if ScaffM8 is unable or unwilling to
							install the Equipment due to the site not being cleared and ready
							as a foresaid. Nevertheless, the Client shall be liable to pay the
							costs on the hire of the Equipment on the terms stated herein.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.6</Text>
						<Text style={styles.subText}>
							The Client shall provide access at any time for ScaffM8 to be
							able to inspect the Equipment on the site (or wherever the
							Equipment may be located).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.7</Text>
						<Text style={styles.subText}>
							Any Equipment above five metres (5m) high may only be erected,
							altered or dismantled under the supervision of a scaffolder
							holding a certificate of competency or license that may be
							required by any legislation or local regulatory authority for the
							purpose of the erection or the dismantling of the Equipment or
							ScaffM8.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.8</Text>
						<Text style={styles.subText}>
							If during the course of the Client’s work, the Equipment is
							modified or removed by the Client to the extent the Equipment is
							no longer compliant with the applicable legislative requirements,
							any rectification work performed by ScaffM8 will be at the
							Client’s expense.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.9</Text>
						<Text style={styles.subText}>
							The Client acknowledges and agrees that:{'\n'}(a) ScaffM8’
							erect and dismantle quotation, unless otherwise agreed, does not
							allow for subsequent visits to the site to adjust or alter the
							Equipment, any additional rectification services will be charged
							as an extra;{'\n'}(b) and any standing scaffold over five metres
							(5m) high or intended to extend over five metres (5m) high
							requires ScaffM8 and the people intending to use the scaffold
							to provide a notification to SafeWork prior to any services
							commencing; and{'\n'}(c) in the event ScaffM8 require access,
							in order to erect, alter or dismantle the Equipment, to an
							adjoining or adjacent property or land to the nominated site, that
							is not owned by the Client, then it is the Client’s responsibility
							to gain permission from the landowner to use the above-mentioned
							property throughout the erection, alteration or dismantling of the
							Equipment. In the event the landowner denies access or use of the
							land or property, the Client shall be liable for all costs
							incurred by ScaffM8 in gaining permission to access and/or
							use the property through any legal process that may be deemed
							necessary.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>9.10</Text>
						<Text style={styles.subText}>
							The Client shall be responsible for:{'\n'}(a) confirming with the
							relevant authorities that all power cables are safe within four
							metres (4m) of the intended Equipment prior to the services
							commencing; and{'\n'}(b) providing ScaffM8, while at the
							site, with adequate access to available water, electricity, toilet
							and washing facilities unless catered for within the Contract
							preliminary in general.
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>10.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Affixation of Equipment to Land or Buildings
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>10.1</Text>
						<Text style={styles.subText}>
							If the Equipment or any part thereof is affixed to any land or
							buildings pursuant to this agreement, and the land or buildings
							are or become the subject of a mortgage or charge whether under
							the PPSA or otherwise at law, then the Client shall, without first
							receiving any request from ScaffM8, obtain the written
							acknowledge of the mortgagee or charge hold (as the case may be)
							that: {'\n'}
							(a) the Equipment or any part thereof is not a fixture for the
							purposes of the mortgage or charge.{'\n'}(b) that the mortgagee or
							charge holder will not make any claim in relation to the Equipment
							or any part; therefore, and{'\n'}(c) that the mortgage or charge
							holder will permit ScaffM8 (whether or not there has been any
							default under the mortgage or charge) to enter upon the land or
							buildings and to remove the Equipment or part thereof.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>11.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Compliance with Laws
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>11.1</Text>
						<Text style={styles.subText}>
							The Client and ScaffM8 shall comply with the provisions of
							all statutes, regulations and bylaws of government, local and
							other public authorities that may be applicable to the Equipment
							including but not limited to, industry best practice guidelines
							for scaffolding, any Australian Standards relating to
							building/construction sites and any other relevant standards or
							legislation.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>11.2</Text>
						<Text style={styles.subText}>
							The Client shall obtain (at the expense of the Client) all
							engineering reports, certificates, and all licenses and approvals
							that may be required for the Equipment hire/placement.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>11.3</Text>
						<Text style={styles.subText}>
							The Client shall be responsible for the submission of notices and
							permits, authority, consents and approvals or bonds (including
							building permits and air rights) relevant to the local authority,
							councils or SafeWork.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>12.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Title
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>12.1</Text>
						<Text style={styles.subText}>
							The Equipment is and will at all times remain the absolute
							property of ScaffM8, and the Client must return the Equipment
							to ScaffM8 upon request to do so.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>12.2</Text>
						<Text style={styles.subText}>
							If the Client fails to return the Equipment to ScaffM8 as is
							required under this Contract or when requested to do so, then
							ScaffM8 or ScaffM8’ agent may (as the invitee of the
							Client) enter upon and into any land and premises owned, occupied
							or used by the Client, or any premises where the Equipment is
							situated and take possession of the Equipment, without being
							responsible for any damage thereby caused. Any costs incurred by
							ScaffM8 as a result of ScaffM8 so repossessing the
							Equipment shall be charged to the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>12.3</Text>
						<Text style={styles.subText}>
							The Client is not authorized to pledge ScaffM8’ credit for
							repairs to the Equipment or to create a lien over the Equipment in
							respect of any repairs.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>13.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Client's Responsibilities
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>13.1</Text>
						<Text style={styles.subText}>
							The Client warrants that any structures to which the Equipment is
							to be erected on is able to withstand all imposed loadings If for
							any reason that ScaffM8, or employees of ScaffM8,
							reasonably form the opinion that the Client’s premises is not safe
							for the erection of the Equipment to proceed then ScaffM8
							shall be entitled to delay erection of the Equipment until ScaffM8
							Access is satisfied that it is safe for the erection to proceed.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>13.2</Text>
						<Text style={styles.subText}>
							It is the Client’s responsibility to give due notice to all local
							and any other authorities of its intention to erect the Equipment
							and to gain any consent required as per clause 11.2.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>13.3</Text>
						<Text style={styles.subText}>
							Scaffold decks are to be cleared of all building materials and
							rubbish before planks are moved or dismantling can commence.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>13.4</Text>
						<Text style={styles.subText}>
							The Client shall be solely responsible for and liable for the
							Equipment (and associated costs where applicable) and the manner
							of use and shall:{'\n'}
							(a) maintain the Equipment as is required by ScaffM8.{'\n'}
							(b) ensure scaffolding ties fixed by ScaffM8 are not removed
							or tampered with as the safety and stability of the scaffold
							depends on these ties.{'\n'}
							(c) not move or relocate the Equipment or any part of the
							Equipment from the site without ScaffM8’ prior written
							approval.{'\n'}
							(d) ensure all overhead and underground services at the site are
							located and identified or relocated, protected or temporarily shut
							down as necessary to ensure a safe working environment for the
							Equipment (including the Delivery, erection, installation and/or
							removal of the Equipment).{'\n'}
							(e) notify ScaffM8 immediately by telephone of the full
							circumstances of any failure or accident in connection with the
							Equipment. The Client is not absolved from the requirements to
							safeguard the Equipment by giving such notification. {'\n'}
							(f) satisfy itself prior to taking delivery of the Equipment that
							the Equipment is suitable for its purposes.{'\n'}
							(g) use the Equipment safely, strictly in accordance with the law,
							only for its intended use, and in accordance with any
							manufacturer’s instruction and Australian Standards, whether
							supplied by ScaffM8 or posted on the Equipment.{'\n'}
							(h) ensure that all persons erecting or using the Equipment are
							suitably instructed in its safe and proper use, and where
							necessary, hold a current certificate of competency and/or are
							fully licensed.{'\n'}
							(i) ensure that the operator of any Equipment is not under the
							influence of alcohol or any drug that may impair their ability to
							operate the Equipment.{'\n'}
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				// ONE SECTION
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>
							{'       '}
						</Text>
						<Text style={styles.subText}>
							(j) comply with all laws relating to the Equipment and its use
							(including, but not limited to work health and Safety (WHS) laws,
							the Australian Building and Industry Codes).{'\n'}
							(k) keep the Equipment in their own possession and control and
							shall not assign the benefit of the hire contract nor be entitled
							to take a lien or grant any encumbrance over the Equipment without
							ScaffM8’ prior written approval. {'\n'}
							(l) not alter or make any additions to the Equipment including but
							without limitation defacing or erasing any identifying mark, plate
							or number on or in the Equipment or in any other manner
							interfering with the Equipment.{'\n'}
							(m) employ the Equipment solely in its own work and not permit the
							Equipment of any part thereof to be used by any other party for
							any other work.{'\n'}
							(n) ensure all overhead power likely to cause a hazard or
							adversely affect the installation of Equipment is turned off prior
							to the installation of the equipment.{'\n'}
							(o) ensure that no digging or excavation work is performed near or
							under the Equipment during the installation or once the Equipment
							is installed.{'\n'}
							(p) not exceed the recommended or legal load and capacity limits
							of the Equipment.{'\n'}
							(q) not use or carry any illegal, prohibited or dangerous
							substance on the Equipment.{'\n'}
							(r) not fix any of the Equipment in such a manner as to make it
							legally a fixture forming part of any freehold.{'\n'}
							(s) on termination of the hire, deliver up the Equipment complete
							with all parts and accessories clean and in good order as
							delivered, fair wear and tear accepted, to ScaffM8.{'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>13.5</Text>
						<Text style={styles.subText}>
							Immediately on request by ScaffM8 the Client will pay: {'\n'}
							(a) the new list price of any Equipment, accessories or
							consumables that are for whatever reason destroyed, written off or
							not returned to ScaffM8.{'\n'}
							(b) all costs incurred in cleaning the Equipment.{'\n'}
							(c) all costs of repairing any damage caused by:{'\n'}
							<Text style={styles.subText}>
								(c) all costs of repairing any damage caused by:{'\n'}
								{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(i) the ordinary
								use of the Equipment up to an amount equal to ten percent (10%)
								of the new list price of the Equipment.
								{'\n'}
								{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(ii) willful or
								negligent actions of the Client or the Client’s employees.{'\n'}
								{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(iii) vandalism,
								or (in ScaffM8’ reasonable opinion) in any way whatsoever
								other than by the ordinary use of the{' '}
								{
									'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
								}
								Equipment by the Client.
								{'\n'}
							</Text>
							(d) any costs incurred by ScaffM8 in dismantling the
							Equipment and returning it to ScaffM8’ premises if the Client
							does not return the Equipment to ScaffM8 premises or any
							pre-agreed pickup location when it was originally agreed that the
							Client would do so.{'\n'}
							(e) any lost hire fees ScaffM8 would have otherwise been
							entitled to for the Equipment, under this, or any other hire
							contract.{'\n'}
							(f) any insurance excess payable in relation to a claim made by
							either the Client or ScaffM8 in relation to any damage caused
							by, or to, the hire Equipment whilst the same is hired by the
							Client and irrespective of whether charged by the Client’s
							insurers or ScaffM8’.{'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Personal Property Securities Act 2009 (“PPSA”)
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.1</Text>
						<Text style={styles.subText}>
							In this clause financing statement, financing change statement,
							security agreement, and security interest has the meaning given to
							it by the PPSA.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.2</Text>
						<Text style={styles.subText}>
							Upon assenting to these terms and conditions in writing the Client
							acknowledges and agrees that these terms and conditions constitute
							a security agreement for the purposes of the PPSA and creates a
							security interest in all Equipment that has previously been
							supplied and that will be supplied in the future by ScaffM8
							to the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.3</Text>
						<Text style={styles.subText}>
							The Client undertakes to:{'\n'}(a) promptly sign any further
							documents and/or provide any further information (such information
							to be complete, accurate and up to date in all respects) which
							ScaffM8 may reasonably require to:{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(i) register a
							financing statement or financing change statement in relation to a
							security interest on the Personal Property{' '}
							{
								'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
							}
							Securities Register.
							{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(ii) register any
							other document required to be registered by the PPSA; or{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(iii) correct a
							defect in a statement referred to in clause 14.3(a)(i) or
							14.3(a)(ii);{'\n'}
							(b) indemnify, and upon demand reimbursement, ScaffM8 for all
							expenses incurred in registering a financing statement or
							financing change statement on the Personal Property Securities
							Register established by the PPSA or releasing any Equipment
							charged thereby. {'\n'}
							(c) not register a financing change statement in respect of a
							security interest without the prior written consent of ScaffM8
							Access{'\n'}
							(d) not register, or permit to be registered, a financing
							statement or a financing change statement in relation to the
							Equipment in favor of a third party without the prior written
							consent of ScaffM8. {'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.4</Text>
						<Text style={styles.subText}>
							ScaffM8 and the Client agree that sections 96, 115 and 125 of
							the PPSA do not apply to the security agreement created by these
							terms and conditions.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.5</Text>
						<Text style={styles.subText}>
							The Client waives their rights to receive notices under sections
							95, 118, 121(4), 130, 132(3)(d) and 132(4) of the PPSA.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.6</Text>
						<Text style={styles.subText}>
							The Client waives their rights as a grantor and/or a debtor under
							sections 142 and 143 of the PPSA.
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.7</Text>
						<Text style={styles.subText}>
							Unless otherwise agreed to in writing by ScaffM8, the Client
							waives their right to receive a verification statement in
							accordance with section 157 of the PPSA.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.8</Text>
						<Text style={styles.subText}>
							The Client must unconditionally ratify any actions taken by ScaffM8
							Access under clauses 14.3 to 14.5.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.9</Text>
						<Text style={styles.subText}>
							Subject to any express provisions to the contrary (including those
							contained in this clause 14), nothing in these terms and
							conditions is intended to have the effect of contracting out of
							any of the provisions the PPSA.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>14.10</Text>
						<Text style={styles.subText}>
							Only to the extent that the hire of the Equipment exceeds a two
							(2) year hire period with the right of renewal shall clause 14
							apply as a security agreement in the form of a PPS Lease in
							respect of Section 20 of the PPSA, in all other matters this
							clause 14 will apply generally for the purposes of the PPSA.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>15.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Security and Charge
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>15.1</Text>
						<Text style={styles.subText}>
							In consideration of ScaffM8 agreeing to supply Equipment, the
							Client charges all of its rights, title and interest (whether
							joint or several) in any land, realty or other assets capable of
							being charged, owned by the Client either now or in the future, to
							secure the performance by the Client of its obligations under
							these terms and conditions (including, but not limited to, the
							payment of any money).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>15.2</Text>
						<Text style={styles.subText}>
							The Client indemnifies ScaffM8 from and against all ScaffM8
							Access’ costs and disbursements including legal costs on a
							solicitor and own client basis incurred in exercising ScaffM8
							Access’ rights under this clause.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>15.3</Text>
						<Text style={styles.subText}>
							The Client irrevocably appoints ScaffM8 and each director of
							ScaffM8 as the Client’s true and lawful attorney/s to perform
							all necessary acts to give effect to the provisions of this clause
							15 including, but not limited to, signing any document on the
							Client’s behalf.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Defects, Warranties and Returns, Competition and Consumer Act 2010
							(CCA)
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.1</Text>
						<Text style={styles.subText}>
							The Client must inspect the Equipment on Delivery and must within
							seven (7) days of Delivery notify ScaffM8 in writing of any
							evident defect/damage, shortage in quantity, or failure to comply
							with the description or quote. The Client must notify any other
							alleged defect in the Equipment as soon as reasonably possible
							after any such defect becomes evident. Upon such notification the
							Client must allow ScaffM8 to inspect the Equipment.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.2</Text>
						<Text style={styles.subText}>
							Under applicable State, Territory and Commonwealth Law (including,
							without limitation the CCA), certain statutory implied guarantees
							and warranties (including, without limitation the statutory
							guarantees under the CCA) may be implied into these terms and
							conditions (Non-Excluded Guarantees).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.3</Text>
						<Text style={styles.subText}>
							ScaffM8 acknowledges that nothing in these terms and
							conditions purports to modify or exclude the Non-Excluded
							Guarantees.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.4</Text>
						<Text style={styles.subText}>
							Except as expressly set out in these terms and conditions or in
							respect of the Non-Excluded Guarantees, ScaffM8 makes no
							warranties or other representations under these terms and
							conditions including but not limited to the quality or suitability
							of the Equipment. ScaffM8’ liability in respect of these
							warranties is limited to the fullest extent permitted by law.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.5</Text>
						<Text style={styles.subText}>
							If the Client is a consumer within the meaning of the CCA, ScaffM8
							Access’ liability is limited to the extent permitted by section
							64A of Schedule 2.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.6</Text>
						<Text style={styles.subText}>
							If ScaffM8 is required to rectify, re-supply, or pay the cost
							of re-supplying any services or Equipment under this clause or the
							CCA, but is unable to do so, then ScaffM8 may refund any
							money the Client has paid for the services or Equipment but only
							to the extent that such refund shall take into account the value
							of any services or Equipment and consumables which have been
							provided to the Client which were not defective.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.7</Text>
						<Text style={styles.subText}>
							If the Client is not a consumer within the meaning of the CCA,
							ScaffM8’ liability for any defect or damage in the services
							or Equipment is:{'\n'}(a) limited to the value of any express
							warranty or warranty card provided to the Client by ScaffM8
							at ScaffM8’ sole discretion.{'\n'}(b) limited to any warranty
							to which ScaffM8 is entitled, if ScaffM8 did not
							manufacture the Equipment.{'\n'}(c) otherwise negated absolutely.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>16.8</Text>
						<Text style={styles.subText}>
							Notwithstanding clauses 16.1 to 16.7 but subject to the CCA, ScaffM8
							Access shall not be liable for any defect or damage which may be
							caused or partly caused by, or arise as a result of:{'\n'}(a)the
							Client failing to properly maintain or store any Equipment.{'\n'}
							(b) the Client interfering with the Equipment in any way without
							ScaffM8’ written approval to do so.{'\n'}(c) the Client using
							the Equipment for any purpose other than that for which it was
							designed.{'\n'}(d) the Client continuing the use of the Equipment
							after any defect became apparent or should have become apparent to
							a reasonably prudent operator or user.{'\n'}(e) the Client failing
							to follow any instructions or guidelines provided by ScaffM8.
							{'\n'}(f) fair wear and tear, any accident, or act of God.
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				// ONE SECTION
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>17.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Client's Responsibilities
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>17.1</Text>
						<Text style={styles.subText}>
							The Client warrants that any structures to which the Equipment is
							to be erected on is able to withstand all imposed loadings If for
							any reason that ScaffM8, or employees of ScaffM8,
							reasonably form the opinion that the Client’s premises is not safe
							for the erection of the Equipment to proceed then ScaffM8
							shall be entitled to delay erection of the Equipment until ScaffM8
							Access is satisfied that it is safe for the erection to proceed.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>17.2</Text>
						<Text style={styles.subText}>
							It is the Client’s responsibility to give due notice to all local
							and any other authorities of its intention to erect the Equipment
							and to gain any consent required as per clause 11.2.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>17.3</Text>
						<Text style={styles.subText}>
							Scaffold decks are to be cleared of all building materials and
							rubbish before planks are moved or dismantling can commence.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>17.4</Text>
						<Text style={styles.subText}>
							The Client shall be solely responsible for and liable for the
							Equipment (and associated costs where applicable) and the manner
							of use and shall:{'\n'}
							(a) maintain the Equipment as is required by ScaffM8.{'\n'}
							(b) ensure scaffolding ties fixed by ScaffM8 are not removed
							or tampered with as the safety and stability of the scaffold
							depends on these ties.{'\n'}
							(c) not move or relocate the Equipment or any part of the
							Equipment from the site without ScaffM8’ prior written
							approval.{'\n'}
							(d) ensure all overhead and underground services at the site are
							located and identified or relocated, protected or temporarily shut
							down as necessary to ensure a safe working environment for the
							Equipment (including the Delivery, erection, installation and/or
							removal of the Equipment).{'\n'}
							(e) notify ScaffM8 immediately by telephone of the full
							circumstances of any failure or accident in connection with the
							Equipment. The Client is not absolved of the requirements to
							safeguard the Equipment by giving such notification.{'\n'}
							(f) satisfy itself prior to taking delivery of the Equipment that
							the Equipment is suitable for its purposes. {'\n'}
							(g) use the Equipment safely, strictly in accordance with the law,
							only for its intended use, and in accordance with any
							manufacturer’s instruction and Australian Standards, whether
							supplied by ScaffM8 or posted on the Equipment.{'\n'}
							(h) ensure that all persons erecting or using the Equipment are
							suitably instructed in its safe and proper use, and where
							necessary, hold a current certificate of competency and/or are
							fully licensed. {'\n'}
							(i) ensure that the operator of any Equipment is not under the
							influence of alcohol or any drug that may impair their ability to
							operate the Equipment.{'\n'}
							(j) comply with all laws relating to the Equipment and its use
							(including, but not limited to work health and Safety (WHS) laws,
							the Australian Building and Industry Codes).{'\n'}
							(k) keep the Equipment in their own possession and control and
							shall not assign the benefit of the hire contract nor be entitled
							to take a lien or grant any encumbrance over the Equipment without
							ScaffM8’ prior written approval.{'\n'}
							(l) not alter or make any additions to the Equipment including but
							without limitation defacing or erasing any identifying mark, plate
							or number on or in the Equipment or in any other manner
							interfering with the Equipment.{'\n'}
							(m) employ the Equipment solely in its own work and not permit the
							Equipment of any part thereof to be used by any other party for
							any other work.{'\n'}
							(n) ensure all overhead power likely to cause a hazard or
							adversely affect the installation of Equipment is turned off prior
							to the installation of the equipment.{'\n'}
							(o) ensure that no digging or excavation work is performed near or
							under the Equipment during the installation or once the Equipment
							is installed.{'\n'}
							(p) not exceed the recommended or legal load and capacity limits
							of the Equipment.{'\n'}
							(q) not use or carry any illegal, prohibited or dangerous
							substance on the Equipment.{'\n'}
							(r) not fix any of the Equipment in such a manner as to make it
							legally a fixture forming part of any freehold.{'\n'}
							(s) on termination of the hire, deliver up the Equipment complete
							with all parts and accessories clean and in good order as
							delivered, fair wear and tear accepted, to ScaffM8.{'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>17.5</Text>
						<Text style={styles.subText}>
							Immediately on request by ScaffM8 the Client will pay: {'\n'}
							(a) the new list price of any Equipment, accessories or
							consumables that are for whatever reason destroyed, written off or
							not returned to ScaffM8.{'\n'}
							(b) all costs incurred in cleaning the Equipment.{'\n'}
							(c) all costs of repairing any damage caused by:{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(i) the ordinary use
							of the Equipment up to an amount equal to ten percent (10%) of the
							new list price of the Equipment.
							{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(ii) willful or
							negligent actions of the Client or the Client’s employees.{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(iii) vandalism, or
							(in ScaffM8’ reasonable opinion) in any way whatsoever other
							than by the ordinary use of the Equipment by the Client.{'\n'}
							(d) any costs incurred by ScaffM8 in dismantling the
							Equipment and returning it to ScaffM8’ premises if the Client
							does not return the Equipment to ScaffM8’s premises or any
							pre-agreed pickup location when it was originally agreed that the
							Client would do so.{'\n'}
							(e) any lost hire fees ScaffM8 would have otherwise been
							entitled to for the Equipment, under this, or any other hire
							contract.{'\n'}
							(f) any insurance excess payable in relation to a claim made by
							either the Client or ScaffM8 in relation to any damage caused
							by, or to, the hire Equipment whilst the same is hired by the
							Client and irrespective of whether charged by the Client’s
							insurers or ScaffM8’.{'\n'}
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>18.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Cancellation
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>18.1</Text>
						<Text style={styles.subText}>
							Without prejudice to any other remedies ScaffM8 may have, if
							at any time the Client is in breach of any obligation (including
							those relating to payment) under these terms of hire ScaffM8
							may repossess the Equipment as per clause 12.2, or suspend or
							terminate the supply of Equipment to the Client and any of its
							other obligations under the terms and conditions. ScaffM8
							will not be liable to the Client for any loss or damage the Client
							suffers because ScaffM8 has exercised its rights under this
							clause.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>18.2</Text>
						<Text style={styles.subText}>
							ScaffM8 may cancel these terms and conditions or cancel
							Delivery of Equipment at any time before the Equipment is
							delivered by giving written notice to the Client. On giving such
							notice ScaffM8 shall repay to the Client any sums paid in
							respect of the Price. ScaffM8 shall not be liable for any
							loss or damage whatsoever arising from such cancellation.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>18.3</Text>
						<Text style={styles.subText}>
							In the event that the Client cancels Delivery of the Equipment the
							Client shall be liable for any and all loss incurred (whether
							direct or indirect) by ScaffM8 as a direct result of the
							cancellation (including, but not limited to, any loss of profits).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>19.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Default and Consequences of Default
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>19.1</Text>
						<Text style={styles.subText}>
							Interest on overdue invoices shall accrue daily from the date when
							payment becomes due, until the date of payment, at a rate of two-
							and one-half percent (2.5%) per calendar month (and at ScaffM8
							Access’ sole discretion such interest shall compound monthly at
							such a rate) after as well as before any judgment.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>19.2</Text>
						<Text style={styles.subText}>
							If the Client owes ScaffM8 any money the Client shall
							indemnify ScaffM8 from and against all costs and
							disbursements incurred by ScaffM8 in recovering the debt
							(including but not limited to internal administration fees, legal
							costs on a solicitor and own client basis, ScaffM8’ contract
							default fee, and bank dishonor fees).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>19.3</Text>
						<Text style={styles.subText}>
							Further to any other rights or remedies ScaffM8 may have
							under this Contract, if the Client has made payment to ScaffM8
							Access, and the transaction is subsequently reversed, the Client
							shall be liable for the amount of the reversed transaction, in
							addition to any further costs incurred by ScaffM8 under this
							clause 19 where it can be proven that such reversal is found to be
							illegal, fraudulent or in contravention to the Client’s
							obligations under this Contract.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>19.4</Text>
						<Text style={styles.subText}>
							Without prejudice to ScaffM8’ other remedies at law ScaffM8
							Access shall be entitled to cancel all or any part of any order of
							the Client which remains unperformed and all amounts owing to
							ScaffM8 shall, whether or not due for payment, become
							immediately payable in the event that:{'\n'}(a) any money payable
							to ScaffM8 becomes overdue, or in ScaffM8’ opinion the
							Client will be unable to meet its payments as they fall due; or
							{'\n'}
							(b) the Client has exceeded any applicable credit limit provided
							by ScaffM8.{'\n'}
							(c) the Client becomes insolvent, convenes a meeting with its
							creditors or proposes or enters into an arrangement with
							creditors, or makes an assignment for the benefit of its
							creditors; or{'\n'}
							(d) a receiver, manager, liquidator (provisional or otherwise) or
							similar person is appointed in respect of the Client or any asset
							of the Client.{'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Privacy Policy
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.1</Text>
						<Text style={styles.subText}>
							All emails, documents, images or other recorded information held
							or used by ScaffM8 is Personal Information, as defined and
							referred to in clause 20.3, and therefore considered Confidential
							Information. ScaffM8 acknowledges its obligation in relation
							to the handling, use, disclosure and processing of Personal
							Information pursuant to the Privacy Act 1988 (“the Act”) including
							the Part IIIC of the Act being Privacy Amendment (Notifiable Data
							Breaches) Act 2017 (NDB) and any statutory requirements, where
							relevant in a European Economic Area (“EEA”), under the EU Data
							Privacy Laws (including the General Data Protection Regulation
							“GDPR”) (collectively, “EU Data Privacy Laws”). ScaffM8
							acknowledges that in the event it becomes aware of any data
							breaches and/or disclosure of the Clients Personal Information,
							held by ScaffM8 that may result in serious harm to the
							Client, ScaffM8 will notify the Client in accordance with the
							Act and/or the GDPR. Any release of such Personal Information must
							be in accordance with the Act and GDPR (where relevant) and must
							be approved by the Client by written consent, unless subject to an
							operation of law.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.2</Text>
						<Text style={styles.subText}>
							Notwithstanding clause 20.1, privacy limitations will extend to
							ScaffM8 in respect of Cookies where transactions for
							purchases/orders transpire directly from ScaffM8’ website.
							ScaffM8 agrees to display reference to such Cookies and/or
							similar tracking technologies, such as pixels and web beacons (if
							applicable), such technology allows the collection of Personal
							Information such as the Client’s:{'\n'}(a) IP address, browser,
							email client type and other similar details.{'\n'}(b) tracking
							website usage and traffic; and{'\n'}(c) reports are available to
							ScaffM8 when ScaffM8 sends an email to the Client, so
							ScaffM8 may collect and review that information
							(“collectively Personal Information”){'\n'}
							In order to enable / disable the collection of Personal
							Information by way of Cookies, the Client shall have the right to
							enable / disable the Cookies first by selecting the option to
							enable / disable, provided on the website prior to proceeding with
							a purchase/order via ScaffM8’ website.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.3</Text>
						<Text style={styles.subText}>
							The Client agrees for ScaffM8 to obtain from a credit
							reporting body (CRB) a credit report containing personal credit
							information (e.g. name, address, D.O.B, occupation, driver’s
							license details, electronic contact (email, Facebook or Twitter
							details), medical insurance details or next of kin and other
							contact information (where applicable), previous credit
							applications, credit history) about the Client in relation to
							credit provided by ScaffM8.
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.4</Text>
						<Text style={styles.subText}>
							The Client agrees that ScaffM8 may exchange information about
							the Client with those credit providers and with related body
							corporates for the following purposes:{'\n'}(a) to assess an
							application by the Client; and/or{'\n'}(b) to notify other credit
							providers of a default by the Client; and/or{'\n'}(c) to exchange
							information with other credit providers as to the status of this
							credit account, where the Client is in default with other credit
							providers; and/or{'\n'}(d) to assess the creditworthiness of the
							Client including the Client’s repayment history in the preceding
							two (2) years.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.5</Text>
						<Text style={styles.subText}>
							The Client consents to ScaffM8 being given a consumer credit
							report to collect overdue payment on commercial credit.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.6</Text>
						<Text style={styles.subText}>
							The Client agrees that personal credit information provided may be
							used and retained by ScaffM8 for the following purposes (and
							for other agreed purposes or required by):{'\n'}(a) the provision
							of Equipment; and/or{'\n'}(b) analyzing, verifying and/or checking
							the Client’s credit, payment and/or status in relation to the
							provision of Equipment; and/or{'\n'}(c) processing of any payment
							instructions, direct debit facilities and/or credit facilities
							requested by the Client; and/or{'\n'}(d) enabling the collection
							of amounts outstanding in relation to the Equipment.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.7</Text>
						<Text style={styles.subText}>
							ScaffM8 may give information about the Client to a CRB for
							the following purposes:{'\n'}(a) to obtain a consumer credit
							report.{'\n'}(b) allow the CRB to create or maintain a credit
							information file about the Client including credit history.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.8</Text>
						<Text style={styles.subText}>
							The information given to the CRB may include:{'\n'}(a) Personal
							information as outlined in 20.3 above.{'\n'}(b) name of the credit
							provider and that ScaffM8 is a current credit provider to the
							Client.{'\n'}(c) whether the credit provider is a licensee.{'\n'}
							(d) type of consumer credit.{'\n'}(e) details concerning the
							Client’s application for credit or commercial credit (e.g., date
							of commencement/termination of the credit account and the amount
							requested).{'\n'}(f) advice of consumer credit defaults, overdue
							accounts, loan repayments or outstanding monies which are overdue
							by more than sixty (60) days, and for which written notice for
							request of payment has been made and debt recovery action
							commenced or alternatively that the Client no longer has any
							overdue accounts and ScaffM8 has been paid or otherwise
							discharged and all details surrounding that discharge (e.g., dates
							of payments).{'\n'}
							(g) information that, in the opinion of ScaffM8, the Client
							has committed a serious credit infringement.{'\n'}(h) advise that
							the amount of the Client’s overdue payment is equal to or more
							than one hundred and fifty dollars ($150).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.9</Text>
						<Text style={styles.subText}>
							The Client shall have the right to request (by e-mail) from ScaffM8
							Access:{'\n'}(a) a copy of the Personal Information about the
							Client retained by ScaffM8 and the right to request that
							ScaffM8 correct any incorrect Personal Information; and{'\n'}
							(b) that ScaffM8 does not disclose any Personal Information
							about the Client for the purpose of direct marketing.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.10</Text>
						<Text style={styles.subText}>
							ScaffM8 will destroy Personal Information upon the Client’s
							request (by e-mail) or if it is no longer required unless it is
							required in order to fulfil the obligations of this Contract or is
							required to be maintained and/or stored in accordance with the
							law.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>20.11</Text>
						<Text style={styles.subText}>
							The Client can make a privacy complaint by contacting ScaffM8
							via e-mail. ScaffM8 will respond to that complaint within
							seven (7) days of receipt and will take all reasonable steps to
							make a decision as to the complaint within thirty (30) days of
							receipt of the complaint. In the event that the Client is not
							satisfied with the resolution provided, the Client can make a
							complaint to the Information Commissioner at www.oaic.gov.au.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>21.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Building and Construction Industry Security of Payments Act 1999
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>21.1</Text>
						<Text style={styles.subText}>
							At ScaffM8’ sole discretion, if there are any disputes or
							claims for unpaid Equipment and/or Services then the provisions of
							the Building and Construction Industry Security of Payments Act
							1999 may apply.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>21.2</Text>
						<Text style={styles.subText}>
							Nothing in this agreement is intended to have the effect of
							contracting out of any applicable provisions of the Building and
							Construction Industry Security of Payments Act 1999 of New South
							Wales, except to the extent permitted by the Act where applicable.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>22.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Services of Notices
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>22.1</Text>
						<Text style={styles.subText}>
							Any written notice given under this Contract shall be deemed to
							have been given and received:{'\n'}(a) by handing the notice to
							the other party, in person.{'\n'}(b) by leaving it at the address
							of the other party as stated in this Contract.{'\n'}(c) by sending
							it by registered post to the address of the other party as stated
							in this Contract.{'\n'}(d) if sent by facsimile transmission to
							the fax number of the other party as stated in this Contract (if
							any), on receipt of confirmation of the transmission.{'\n'}(e) if
							sent by email to the other party’s last known email address.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>22.2</Text>
						<Text style={styles.subText}>
							Any notice that is posted shall be deemed to have been served,
							unless the contrary is shown, at the time when by the ordinary
							course of post, the notice would have been delivered.
						</Text>
					</View>
				</View>
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>23.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							Trusts
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>23.1</Text>
						<Text style={styles.subText}>
							If the Client at any time upon or subsequent to entering into the
							Contract is acting in the capacity of trustee of any Trust
							(“Trust”) then whether or not ScaffM8 may have notice of the
							Trust, the Client covenants with ScaffM8 as follows:{'\n'}(a)
							the Contract extends to all rights of indemnity which the Client
							now or subsequently may have against the Trust and the trust fund.
							{'\n'}(b) the Client has full and complete power and authority
							under the Trust to enter into the Contract and the provisions of
							the Trust do not purport to exclude or take away the right of
							indemnity of the Client against the Trust or the trust fund. The
							Client will not release the right of indemnity or commit any
							breach of trust or be a party to any other action which might
							prejudice that right of indemnity.{'\n'}(c) the Client will not
							without consent in writing of ScaffM8 (ScaffM8 will not
							unreasonably withhold consent), cause, permit, or suffer to happen
							any of the following events.{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(i) the removal,
							replacement or retirement of the Client as trustee of the Trust.
							{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(ii) any alteration
							to or variation of the terms of the Trust.{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(iii) any
							advancement or distribution of capital of the Trust; or{'\n'}
							{'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}(iv) any
							resettlement of the trust property.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							General
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.1</Text>
						<Text style={styles.subText}>
							The failure by either party to enforce any provision of these
							terms and conditions shall not be treated as a waiver of that
							provision, nor shall it affect that party’s right to subsequently
							enforce that provision. If any provision of these terms and
							conditions shall be invalid, void, illegal or unenforceable the
							validity, existence, legality and enforceability of the remaining
							provisions shall not be affected, prejudiced or impaired.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.2</Text>
						<Text style={styles.subText}>
							These terms and conditions and any contract to which they apply
							shall be governed by the laws of New South Wales, the state in
							which ScaffM8 has its principal place of business and are
							subject to the jurisdiction of the Sydney Courts in that state.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.3</Text>
						<Text style={styles.subText}>
							Subject to clause 16, ScaffM8 shall be under no liability
							whatsoever to the Client for any indirect and/or consequential
							loss and/or expense (including loss of profit) suffered by the
							Client arising out of a breach by ScaffM8 of these terms and
							conditions (alternatively ScaffM8’ liability shall be limited
							to damages which under no circumstances shall exceed the Price).
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.4</Text>
						<Text style={styles.subText}>
							ScaffM8 may licence and/or assign all or any part of its
							rights and/or obligations under this Contract without the Client’s
							consent.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.5</Text>
						<Text style={styles.subText}>
							The Client cannot assign or licence without the written approval
							of ScaffM8.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.6</Text>
						<Text style={styles.subText}>
							ScaffM8 may elect to subcontract out any part of the
							provision services but shall not be relieved from any liability or
							obligation under this Contract by so doing. Furthermore, the
							Client agrees and understands that they have no authority to give
							any instruction to any of ScaffM8’ sub-contractors without
							the authority of ScaffM8.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.7</Text>
						<Text style={styles.subText}>
							The Client agrees that ScaffM8 may amend their general terms
							and conditions for subsequent future contracts with the Client by
							disclosing such to the Client in writing. These changes shall be
							deemed to take effect from the date on which the Client accepts
							such changes, or otherwise at such time as the Client makes a
							further request for ScaffM8 to provide Equipment on hire to
							the Client.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.8</Text>
						<Text style={styles.subText}>
							Neither party shall be liable for any default due to any act of
							God, war, terrorism, strike, lock-out, industrial action, fire,
							flood, storm or other event beyond the reasonable control of
							either party.
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>24.9</Text>
						<Text style={styles.subText}>
							Both parties warrant that they have the power to enter into this
							Contract and have obtained all necessary authorizations to allow
							them to do so, they are not insolvent and that this Contract
							creates binding and valid legal obligations on them.
						</Text>
					</View>
				</View>
			</Page>
			// FIT AS MANY "SECTIONS" PER PAGE AS POSSIBLE
			<Page size="A4" style={styles.page}>
				<Heading />
				// ONE SECTION
				<View style={{ flexDirection: 'column', width: 400 }}>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>
							SECTION NUMBER.
						</Text>
						<Text
							style={[styles.subText, { fontSize: 9, fontWeight: 'semibold' }]}>
							SECTION TITLE
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>
							SUB SECTION NUMBER.1
						</Text>
						<Text style={styles.subText}>
							SUB SECTION TEXT WITH BULLETS{'\n'}
							(a) BULLET ONE{'\n'}
							(b) BULLET TWO{'\n'}
						</Text>
					</View>
					<View style={{ flexDirection: 'row', marginBottom: 4 }}>
						<Text style={{ fontSize: 10, marginHorizontal: 8 }}>
							SUB SECTION NUMBER.2
						</Text>
						<Text style={styles.subText}>SUB SECTION TEXT</Text>
					</View>
				</View>
			</Page>
		</Document>
	)
}
