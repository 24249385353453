import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import {
	TimesheetServices,
	DataTableServices,
	VisitTimesheetsServices,
	StaffServices,
	VisitServices,
} from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveTimesheet } from 'components/Timesheets'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { EditTimesheet } from 'components/Timesheets/Form'

export const VisitsBasedTimesheetsPage = () => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheets()

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: ['name', 'job', 'site', 'visit_id', 'date', 'status'],
	})

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
	}) => {
		let total = 0

		if (staffTimesheets) {
			for (const timesheet of staffTimesheets) {
				if (
					Number(timesheet.staff_id) === row.staff_id &&
					timesheet.status !== 'Pending' && (Number(timesheet.hours) >= 0)
				) {
					const hours = Number(timesheet.hours)
					total += hours
				}
			}
		}
		return total
	}

	const headerTemplate = (data: {
			name: string
	}) => (
		<span className="text-gray-900 font-bold">
			{data?.name}
		</span>
	)

	const footerTemplate = (data: any) => (
		<>
			<td
				colSpan={3}
				style={{ textAlign: 'right' }}
				className="bg-gray-100 font-normal">
				Total Hours
			</td>
			<td colSpan={1} className="bg-gray-100 font-semibold">
				{calculateTimesheetTotal(data)}
			</td>
		</>
	)

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data: visitData, isLoading: visitLoading } = VisitServices.useVisits()

	if (timesheetLoading || staffLoading || visitLoading) {
		return <div>Loading...</div>
	}
	if (staffData === undefined) {
		return <div>Staff data is undefined</div>
	}

	const staffDataMap = new Map(staffData.map((staff: any) => [staff.id, staff]))
	const visitDataMap = new Map(visitData.map((visit: any) => [visit.id, visit]))

	let staffTimesheets: any[] = []
	for (const timesheet of timesheetData) {
		const newTimesheets: any[] = []
		const uniqueStaffIdsSet: string[] = Array.from(new Set(timesheet.staff_ids))
		for (const staff of uniqueStaffIdsSet) {
			const staffCopy: any = staffDataMap.get(Number(staff))
			const staffData: any = {...staffCopy}
			const visit: any = visitDataMap.get(Number(timesheet.visit_id))
			const start_time = moment(timesheet.time_in, 'HH:mm')
			const end_time = moment(timesheet.time_off, 'HH:mm')
			const difference: number = end_time.diff(start_time, 'hours', true)
			var deep_copy_name = (' ' + staffData.staff_name).slice(1);
			newTimesheets.push({
				staff_id: Number(staff),
				id:
					String(visit?.jobData?.id) +
					'_' +
					String(visit?.id) +
					'_' +
					String(staff),
				visit_id: visit?.id,
				name: deep_copy_name,
				start_time: timesheet.time_in,
				end_time: timesheet.time_off,
				date: visit?.date,
				job: visit?.jobData?.job_num,
				site: visit?.jobData?.site,
				hours: difference,
				staffData: staffData,
				timesheet_status: timesheet.status,
			})
		}
		staffTimesheets = [...staffTimesheets, ...newTimesheets]
	}

	console.log(staffTimesheets)

	return (
		<div className="card">
			<PageHeading title="Visit Timesheets" />
			<Container>
				<DataTable
					ref={dataTableReference}
					value={staffTimesheets}
					loading={timesheetLoading || staffLoading || visitLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					groupRowsBy="name"
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerTemplate}
					rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					header={header}
					emptyMessage="No Timesheets found.">
					<Column
						field="name"
						header="Staff"
						style={{ minWidth: '5rem' }}
					/>
					<Column
						field="visit_id"
						header="Visit #"
						style={{ minWidth: '2rem' }}
					/>
					<Column
						field="job"
						header="Job #"
						style={{ minWidth: '2rem' }}
					/>
					<Column
						field="site"
						header="Site"
						style={{ minWidth: '5rem' }}
					/>
					<Column
						header="Date"
						field="date"
						style={{ minWidth: '5rem' }}
					/>
					<Column
						header="Time On"
						field="start_time"
						style={{ minWidth: '5rem' }}
					/>
					<Column
						header="Time Off"
						field="end_time"
						style={{ minWidth: '5rem' }}
					/>
					<Column
						header="Hours"
						field="hours"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						header="Status"
						field="timesheet_status"
						bodyClassName="p-text-center"
						style={{ width: '10rem' }}
						body={(row) => (
							<Badge type={row.timesheet_status} text={row.timesheet_status} />
						)}
					/>
				</DataTable>
			</Container>
		</div>
	)
}
